<template>
  <div class="text-scroll">
    <div class="text-answer-list">
      <template v-for="(item, index) in selectedAnswerList">
        <div :class="`colume${currentAnswerTab === 1 ? '1' : '5'} `" v-if="shouldShow(item)" :key="index" :id="item._id">
          <div :class="itemClasses(item, currentAnswerTab)" @click="onSelected(item)">
            <div class="text-list-item" :class="classFn(item)">
              <div :class="`text_area ${!flag_1 && 'full-text-area'}`">
                <el-button
                  style="position: absolute; top: 1rem; right: 0.5rem; z-index: 10"
                  v-if="['video', 'audio'].includes(Fn.fileType(item.content)) && $route.name === 'd'"
                  icon="el-icon-monitor"
                  :disabled="isStudentPaced"
                  circle
                  @click="$store.dispatch('fullPlay', {fullPlayUrl: Fn.hashToUrl(item.content), fullPlayOn: 'projector'})" />
                <div :class="`remark-item-content ${item.type === 'text' && 'content-text-scroll'}`">
                  <VideoPlayer
                    v-if="Fn.fileType(item.content) === 'video'"
                    controlslist="nodownload"
                    :controls="true"
                    :src="item.content"
                    style="height: 200px" />
                  <div v-else-if="Fn.fileType(item.content) === 'audio'" style="width: 100%; padding-right: 50px">
                    <AudioPlayer :url="item.content" />
                  </div>
                  <div class="answer-image" style="padding-bottom: 60%" v-else-if="Fn.fileType(item.content) === 'image'">
                    <Base64image :url="item.content" :showPreview="true" />
                  </div>
                  <div
                    class="answer-image"
                    style="padding-bottom: 60%"
                    v-else-if="Fn.fileType(item.content) === 'task'"
                    @click="$store.dispatch('upFullDoc', {url: item.content})">
                    <Base64image v-if="item.content && item.content.includes(':')" :url="item.content.split(':')[0]" />
                  </div>
                  <div class="remark-file" v-else @click="$store.dispatch('upFullDoc', {url: item.content})">
                    <div class="file-icon" :class="Fn.fileIcon(item.content)"></div>
                    <div class="file-name" style="flex: 1">
                      <p class="file-name">{{ item.content.split(':').pop() }}</p>
                    </div>
                  </div>
                </div>
                <span class="text_static" v-if="flag_1 && isTextList">
                  {{ index + 1 + ' of ' + selectedAnswerList.length }}
                </span>
              </div>
              <div class="text-footer" v-if="flag_1">
                <StudentResponseOptBar :item="{...item, title: item.content}" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import StudentResponseOptBar from './studentResponseOptBar.vue'
import {mapGetters, mapState, mapActions} from 'vuex'
import AudioPlayer from '../common/audioPlayer.vue'
import Base64image from '../base64image.vue'
import FileAnswerIcon from '../common/fileAnswerIcon.vue'
export default {
  computed: {
    ...mapGetters({
      currentPageAnswerList: 'student/currentPageAnswerList',
      selectedMembers: 'student/selectedMembers',
    }),
    ...mapGetters(['isStudentPaced']),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    ...mapState(['syncUI']),
    currentAnswerTab() {
      setTimeout(() => {
        this.scrollToItem(this.syncUI.currentAnswer)
      }, 250)
      return this.$store.getters['teacher/answerTab'](this.currentPageIndex)
    },
    isResponseShow() {
      this.scrollToItem(this.syncUI.currentAnswer)

      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
    isTextList() {
      // 计算是否显示统计 1 of n
      const one = this.selectedAnswerList[0]
      const frr = _.filter(this.selectedAnswerList, {student_user_id: one.student_user_id})
      if (frr.length > 1 && frr.length === this.selectedAnswerList.length) return true
      return false
    },
  },
  components: {StudentResponseOptBar, AudioPlayer, Base64image, FileAnswerIcon},
  props: {
    shouldShow: {type: Function, default: () => {}},
    selectedAnswerList: {type: Array, default: []},
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    classFn: {
      type: Function,
      default: () => {
        return {}
      },
    },
    itemClasses: {
      type: Function,
      default: () => {
        return {}
      },
    },
    scrollToItem: {
      type: Function,
      default: () => {
        return {}
      },
    },
    onSelected: {
      type: Function,
      default: () => {
        return {}
      },
    },
    flag_1: {type: Boolean, default: true},
  },
  data() {
    return {
      isStudent: false,
      sortValue: 1,
    }
  },
  created() {},
  mounted() {
    this.isStudent = this.$route.name === 's'
  },
  watch: {
    syncUI: {
      handler(o) {
        let answer = null
        this.selectedAnswerList.forEach((a, idx) => {
          if (o.currentAnswer == a._id) {
            answer = a
          }
        })
        if (answer && answer._id) {
          this.scrollToItem(answer._id)
        }
      },
      deep: true,
    },
  },
  methods: {},
}
</script>
