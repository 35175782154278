<template>
  <div>
    <div class="text-scroll" v-if="currentAnswerTab !== 3">
      <div class="text-answer-list">
        <template v-for="(item, index) in selectedMarksList">
          <div :class="`colume${currentAnswerTab === 1 ? '1' : '5'} `" :id="item._id" v-if="shouldShow(item)" @click="onSelected(item)" :key="index">
            <div :class="itemClasses(item, currentAnswerTab)">
              <div class="flex_v text-list-item" :class="classFn(item)">
                <div :class="`text_area ${currentAnswerTab === 1 ? '' : 'columText'} ${!flag_1 && 'full-text-area'}`">
                  <div :class="`remark-item-content1 ${Fn.fileType(item.content) === 'file' && 'content-text-scroll'}`">
                    <RemarkImage :item="item" />
                  </div>
                  <span class="text_static" v-if="flag_1 && isTextList">
                    {{ index + 1 + ' of ' + selectedMarksList.length }}
                  </span>
                </div>
                <div class="text-footer" v-if="flag_1">
                  <StudentResponseOptBar :item="item" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="teacherppt-outer flex1" v-else-if="currentAnswerTab === 3">
      <RemarkList :items="selectedMarksList" :itemClasses="itemClasses" :scrollToItem="scrollToItem" :onSelected="onSelected" />
    </div>
  </div>
</template>

<script>
import StudentResponseOptBar from './studentResponseOptBar.vue'
import {mapGetters, mapState, mapActions} from 'vuex'
import AudioPlayer from '../common/audioPlayer.vue'
import RemarkImage from './remark/remarkImage.vue'
import PreviewButton from '../common/previewButton.vue'
import RemarkList from './remark/remarkList.vue'
export default {
  computed: {
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    ...mapState(['syncUI']),
    ...mapGetters('student', ['selectedGroupMembers']),
    ...mapGetters(['getPageId']),
    currentAnswerTab() {
      const ci = this.$store.getters['teacher/answerTab'](this.currentPageIndex) 
      let isRemarkList = false
      if (ci === 3) {
        isRemarkList = true 
      }
      setTimeout(() => {
        this.scrollToItem(this.syncUI.currentAnswer, isRemarkList)
      }, 250)
      return ci
    },
    isResponseShow() {
      const ci = this.$store.getters['teacher/answerTab'](this.currentPageIndex) 
      let isRemarkList = false
      if (ci === 3) {
        isRemarkList = true 
      }
      this.scrollToItem(this.syncUI.currentAnswer, isRemarkList)
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
    isTextList() {
      // 计算是否显示统计 1 of n
      const one = this.selectedMarksList[0]
      const frr = _.filter(this.selectedMarksList, {uid: one.uid})
      if (frr.length > 1 && frr.length === this.selectedMarksList.length) return true
      return false
    },
  },
  components: {StudentResponseOptBar, AudioPlayer, RemarkImage, PreviewButton, RemarkList},
  props: {
    shouldShow: {type: Function, default: () => {}},
    selectedMarksList: {type: Array, default: []},
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    classFn: {
      type: Function,
      default: () => {
        return {}
      },
    },
    itemClasses: {
      type: Function,
      default: () => {
        return {}
      },
    },
    scrollToItem: {
      type: Function,
      default: () => {
        return {}
      },
    },
    onSelected: {
      type: Function,
      default: () => {
        return {}
      },
    },
    flag_1: {type: Boolean, default: true},
  },
  data() {
    return {
      isTextChanging: false,
      timeoutId: null,
      duplication: false,
      isStudent: false,
      changeUser: '', //当前是哪个item发生了变化
      changeItemId: '', //当前是哪个item发生了变化
      sortValue: 1,
    }
  },
  created() {},
  mounted() {
    this.isStudent = this.$route.name === 's'
  },
  watch: {
    syncUI: {
      handler(o) {
        let mark = null
        this.selectedMarksList.forEach((m, idx) => {
          if (o.currentAnswer == m._id) {
            mark = m
          }
        })
        if (mark && mark._id) {
          this.scrollToItem(mark._id, this.currentAnswerTab === 3 ? true : false)
        }
      },
      deep: true,
    },
  },
  methods: {},
}
</script>
