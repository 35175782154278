<template>
  <div class="answer-footer" v-if="item._id">
    <studentInfo :data="{uid: item.uid, last: item.updatedAt}" />
    <div class="opt_area" v-if="isDash || (isStudent && !isMe)">
      <el-tooltip v-if="['text','choice'].includes(item.type) && $route.name === 'd' && item.content && !isStudentPaced" class="item" effect="dark" content="Draw it" placement="top-start">
        <i @click="setSyncUI({ drawUrl: Fn.hashToUrl(getPage.pic || getPage.url), drawShow: false, drawText: item._id})" class="answer-footer-button draw"></i>
      </el-tooltip>

      <el-tooltip effect="dark" content="Case study" placement="top-start" v-if="!isStudent">
        <svg
          @click="starAnswer"
          t="1643296059254"
          class="answer-footer-button"
          style="width: 25px; height: 25px; margin-right: 7px"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="17663"
          width="64"
          height="64">
          <path
            d="M469.333333 85.333333a384 384 0 0 1 300.032 623.658667l139.306667 139.349333a42.666667 42.666667 0 1 1-60.330667 60.330667l-139.349333-139.306667A384 384 0 1 1 469.333333 85.333333z m0 85.333334a298.666667 298.666667 0 1 0 0 597.333333 298.666667 298.666667 0 0 0 0-597.333333z"
            :fill="`${item.star ? '#f7d567' : '#000000'}`"
            p-id="17664"></path>
        </svg>
      </el-tooltip>

      <el-tooltip v-if="commentVis" class="item" effect="dark" content="Feedback" placement="top-start">
        <i @click="comment" :class="`answer-footer-button ${isCommented ? 'message' : 'unmessage'} `"></i>
      </el-tooltip>

      <el-popover placement="top-start" width="100" trigger="hover" v-if="!isStudent">
        <div class="response_opt" @click="hideResponse()">{{ !item.show_response ? 'Hide' : 'Show' }} Response</div>
        <i slot="reference" class="answer-footer-button pop"></i>
      </el-popover>
    </div>
  </div>
</template>
<script>
import {ModalEventsTypeEnum} from '@/socket/socketEvents'
import studentInfo from '../common/studentInfo.vue'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
export default {
  components: {studentInfo},
  props: {
    item: {type: Object},
  },
  computed: {
    ...mapState({
      feedBackAnswerIds: (state) => state.teacher.feedBackAnswerIds,
    }),
    ...mapState(['syncUI']),
    ...mapGetters(['getPage', 'isStudentPaced']),
    ...mapGetters('teacher', ['memberNames']),
    ...mapGetters('student', ['groupMemberNames']),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    currentAnswerTab() {
      return this.$store.getters['teacher/answerTab'](this.currentPageIndex)
    },
    isCommented() {
      return this.feedBackAnswerIds.includes(this.item._id)
    },
    commentVis() {
      return this.isDash || currentUserId !== this.item.uid
    },
  },
  data() {
    return {
      size: 20,
      isMe: currentUserId === this.item.uid,
      isDash: location.href.includes('/d/'),
      isStudent: location.href.includes('/s/'),
    }
  },
  methods: {
    ...mapMutations('teacher', ['setCommentData']),
    ...mapActions(['setSyncUI']),
    starAnswer() {
      const {star} = this.item
      const data = {_id: this.item._id, star: star == 1 ? 0 : 1}
      this.$store.dispatch('student/updateAnswerStarOrResponse', data)
      this.$forceUpdate()
    },
    comment() {
      this.showComment(ModalEventsTypeEnum.TEXT)
    },
    showComment(type) {
      this.setCommentData({...this.item, commentType: type})
    },
    hideResponse() {
      const {show_response} = this.item
      const data = {_id: this.item._id, show_response: show_response == 1 ? 0 : 1}
      this.$store.dispatch('student/updateAnswerStarOrResponse', data)
      this.$forceUpdate()
    },
    video() {
      this.showComment(ModalEventsTypeEnum.VIDEO)
    },
    audio() {
      this.showComment(ModalEventsTypeEnum.AUDIO)
    },
  },
}
</script>

<style scoped>
.answer-footer {
  padding-top: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  /* padding: 0 9px; */
}
.answer-footer .el-icon-edit {
  font-size: 1.2rem; 
}
.emoji_area {
  font-family: PingFang SC;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user_icon {
  width: 52px;
  height: 52px;
  border-radius: 26px;
  background-color: red;
  overflow: hidden;
  line-height: 52px;
  text-align: center;
  margin-left: 5px;
}
.user_info {
  height: auto;
  color: #1296db;
  font-size: 15px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
}
.hide {
  visibility: hidden;
}

.opt_area {
  display: flex;
  height: 100%;
  width: 120px;
  justify-content: flex-end;
  align-items: center;
}
.response_opt {
  display: flex;
  height: 20px;
  width: 100%;
  color: #1296db;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.answer-footer-button {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-position: 0 0;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  display: block;
}
.answer-footer-button.star {
  background-image: url(../../assets/picture/started.png);
  margin-right: 16px;
}
.answer-footer-button.unstar {
  background-image: url(../../assets/picture/unstar.png);
  margin-right: 16px;
}
.answer-footer-button.message {
  background-image: url(../../assets/picture/message-selected.png);
}
.answer-footer-button.unmessage {
  background-image: url(../../assets/picture/message.png);
}
.answer-footer-button.draw{
  margin-right: 7px;
  background-image: url(../../assets/picture/draw.png);
}
.answer-footer-button.pop {
  background-image: url(../../assets/picture/pop-icon.png);
  background-size: 4px 20px;
  background-position: 16px 0;
  width: 20px;
}
.video {
  width: 200px;
  height: 100px;
  position: fixed;
  top: 100px;
  left: 300px;
  z-index: 999;
}
</style>
