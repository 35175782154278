<template>
  <div class="remark-modal" ref="remarkmodal">
    <!-- <div class="dash-header-left" @click="handleBack"></div> -->
    <div class="remark-mask-inner" ref="remarkmaskinner">
      <div
        class="pointer-area"
        @mousedown="startMove($event)"
        @mousemove="mouseMoving($event)"
        @mouseup="mouseEnd($event)"
        @mouseleave="mouseLeave($event)"
        onselectstart="return false"
        @drag="pauseEvent($event)">
        <template v-for="(item, index) in [...currentMark, ...myPageAnswerList]">
          <el-popover
            :key="item._id == -1 ? index : item._id"
            placement="top"
            trigger="hover"
            :append-to-body="true"
            :visible-arrow="false"
            :disabled="isMovingPos"
            popper-class="transparent-content"
            :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
            <ul class="pop-color-list">
              <li class="pop-color-item" v-for="color in hoverColors" :key="color" @click.stop="pickColor(item, index, color)">
                <div class="pop-color-item-box" :style="`background-color:${color}`"></div>
              </li>
            </ul>
            <div
              slot="reference"
              v-if="item.point.pointType !== 'box'"
              :class="`markitem ${currentRemarkIndex === index || !item._id ? 'markitemhover' : ''}`"
              :style="`top:${item._id && item._id == moveItem._id ? moveItem.pos?.top : item.point?.top}px;left:${item._id && item._id == moveItem._id ? moveItem.pos?.left: item.point?.left}px;`"
              @mousedown.stop="selectMark(item, index)"
              @mouseup.stop="mouseEnd($event)"
              @mouseleave.stop
              @click.stop>
              <div class="markitemopacity innermark" :style="`background-color:${item.point.background}; `" />
            </div>
            <div
              slot="reference"
              v-else-if="item.point.pointType === 'box'"
              :class="`markitembox ${currentRemarkIndex === index || !item._id ? 'markboxhover' : ''}`"
              :style="`top:${item.point?.top - 6}px; left:${item.point?.left - 6}px;`"
              @mousedown.stop="selectMark(item, index)"
              @click.stop="mouseEnd($event)"
              @mouseup.stop
              @mouseleave.stop>
              <div
                class="markitemopacity"
                :style="`width:${item.point.width}px;
                  height:${item.point.height}px;
                  border: 2px solid ${item.point.background}`" />
            </div>
          </el-popover>
        </template>
        <div v-if="markType === 2 && isBoxing" class="dragbg" @click.stop />
        <div
          v-if="markType === 2 && isBoxing"
          :data-op="`${nextPosition.offsetY}, ${currentPosition.offsetY}`"
          :style="`width:${Math.abs(nextPosition.offsetX - currentPosition.offsetX)}px;
          height:${Math.abs(nextPosition.offsetY - currentPosition.offsetY)}px;
          position: absolute;
          left: ${Math.min(currentPosition.offsetX, nextPosition.offsetX)}px;
          top: ${Math.min(currentPosition.offsetY, nextPosition.offsetY)}px;
          border: 2px solid ${this.color}`"></div>
        <div v-show="isMovingPos" class="pointer-area"></div>
      </div>
    </div>
    <div v-if="disable" class="transformmask"></div>
  </div>
</template>
<script>
import colorSelector from '@/utils/color'
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
export default {
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPosition: {
        left: 0,
        top: 0,
        content_width: 0,
        content_height: 0,
        offsetX: 0,
        offsetY: 0,
        background: '#caf982',
        width: 0,
        height: 0,
        pointType: 'point',
      },
      currentMark: [],
      nextPosition: {offsetX: 0, offsetY: 0},
      buttonVisiable: false,
      recordVisiable: false,
      sendBusyStatus: false,
      modalVisable: false,
      color: 'rgb(241, 255, 0)',
      colors: ['#caf982', 'red', '#ec808d', '#facd91', '#ffff80', '#80ffff', '#81d3f8', '#8080ff', '#c280ff'],
      widthValue: 3,
      markType: 1, // 1 == point  2 = box
      isBoxing: false,
      buttonPosition: {left: 0, top: 0},
      startMoveEnable: false,
      hoverColors: [
        'rgb(241, 255, 0)',
        'rgb(0, 255, 54)',
        'rgb(255, 0, 156)',
        'rgb(255, 0, 0)',
        'rgb(0, 240, 255)',
        'rgb(216, 0, 255)',
        'rgb(255, 200, 0)',
        '#15c39a',
        'rgb(0, 0, 0)',
      ],
      isMovingPos: false,
      moveItem: {},
      movingPos: {},
      ratio: 1,
      resizeRemarkDelay: null,
    }
  },
  computed: {
    ...mapGetters('student', ['myPageAnswerList']),
    ...mapState({
      currentInputType: (state) => state.remark.currentInputType,
      currentRemarkIndex: (state) => state.remark.currentRemarkIndex,
      currentRemarkOptions: (state) => state.remark.currentRemarkOptions,
      isViewGroup: (state) => state.student.isViewGroup,
    }),
  },
  watch: {
    currentRemarkOptions() {
      console.log(this.currentRemarkOptions)
      if (!this.currentRemarkOptions && this.sendBusyStatus) {
        // sendBusyStatus 表示当前有一次操作未完成
        this.closeRecord()
      }
    },
  },
  mounted() {
    setTimeout(this.resizeRemark, 500)
    window.onresize = () => {
      if (this.resizeRemarkDelay) clearTimeout(this.resizeRemarkDelay)
      this.resizeRemarkDelay = setTimeout(this.resizeRemark, 50)
    }
  },
  beforeDestroy() {
    window.onresize = null
  },
  methods: {
    ...mapMutations('remark', []),
    ...mapActions('remark', ['moveRemarkItem', 'changeRemarkIndex', 'setCurrentRemarkOptions']),
    resizeRemark() {
      if (!this.$refs.remarkmodal) return
      const w = this.$refs.remarkmodal.clientWidth
      if (w === 0) return
      this.ratio = w / 960
      this.$refs.remarkmaskinner.style.transform = `translateY(-50%) translateX(-50%) scale(${this.ratio})`
    },
    drag(e) {
      console.log(e, 'drag')
    },
    resetPosition() {
      ;(this.currentPosition = {
        left: 0,
        top: 0,
        content_width: 0,
        content_height: 0,
        offsetX: 0,
        offsetY: 0,
        background: '#caf982',
      }),
        (this.nextPosition = {offsetX: 0, offsetY: 0})
    },
    pickColor(item, index, background) {
      window.preventSendRemark = true
      console.log('pickColor', window.preventSendRemark)
      console.log(item._id, background)
      if (item._id) {
        this.$store.dispatch('student/upSertResponse', {_id: item._id, 'point.background': background})
      } else {
        this.currentPosition = {
          ...this.currentPosition,
          background,
        }
        this.currentMark = [{point: this.currentPosition}]
        this.setCurrentRemarkOptions(this.currentPosition)
      }
    },
    selectMark(item, index) {
      this.isMovingPos = true
      this.moveItem = item
      if (!this.recordVisiable && item._id) {
        //this.closeRecord();
        this.changeRemarkIndex(index)
      }
    },
    changePos(e) {
      // if(this.movingPos.left < 10 || this.movingPos.top < 10 ) return false
      const item = this.moveItem
      const {left, top} = this.movingPos
      let newPos = {}
      const mx = item.point.left + item.point.top - left - top
      if (mx > 100) return
      if (item.point.pointType !== 'box') {
        newPos = {left: left - 15, top: top - 15}
        // newPos = { left, top }
      } else {
        newPos = {
          left: left - item.point.width / 2 + 6,
          top: top - item.point.height / 2 + 6,
        }
      }
      this.$forceUpdate()
      this.moveItem.pos = newPos
      if (item._id) {
        //this.$store.dispatch('student/upSertResponse', {_id: item._id, point: Object.assign({}, item.point, newPos)})
      } else {
        Object.assign(item.point, newPos)
        this.currentPosition = {...this.currentPosition, ...newPos}
      }
    },
    updateItemData() {
      if (this.moveItem.id) {
        this.updateOneRemarkItem(this.moveItem)
      }
      this.isMovingPos = false
    },
    markup(e) {
      // console.log('click')
      if (this.markType === 1) {
        // 正在进行 comment 记录，不允许打点
        if (this.recordVisiable) return false
        const {offsetX, offsetY} = e
        const left = offsetX - 15
        const top = offsetY - 15
        const {offsetHeight, offsetWidth} = e.target
        this.currentPosition = {
          left,
          top,
          content_width: offsetWidth,
          content_height: offsetHeight,
          offsetX,
          offsetY,
          background: this.color,
        }
        this.markPointer()
      }
    },
    startMove(e) {
      if (this.isMovingPos) {
        return false
      }
      this.startMoveEnable = true
      if (this.recordVisiable) return false

      this.changeMarkType(2)
      const {offsetX, offsetY, x, y} = e
      const left = offsetX
      const top = offsetY
      const {offsetHeight, offsetWidth} = e.target
      this.currentPosition = {
        left,
        top,
        content_width: offsetWidth,
        content_height: offsetHeight,
        offsetX,
        offsetY,
        background: this.color,
      }
      this.nextPosition = {offsetX, offsetY}

      console.log('index======startMove', offsetX, offsetY, x, y, e)
    },
    mouseMoving(e) {
      // 移动过程中禁止提交
      if (this.isMovingPos) {
        window.preventSendRemark = true
        this.movingPos = {left: e.offsetX, top: e.offsetY}
        this.changePos()
        return false
      }
      // console.log('====', e)
      if (!this.startMoveEnable) return false
      if (this.recordVisiable) return false
      // console.log('mouseMoving', this.recordVisiable , this.markType, this.isBoxing)
      // if(!this.recordVisiable && this.markType !== 2) {
      //   this.changeMarkType(2)
      // }
      const {offsetX, offsetY, clientX, clientY} = e
      if (offsetX - this.nextPosition.offsetX > 20 || offsetY - this.nextPosition.offsetY > 20) {
        this.isBoxing = true
      }
      if (this.markType === 2 && this.isBoxing) {
        this.nextPosition = {
          offsetX: offsetX,
          offsetY: offsetY,
        }
      }
    },
    mouseLeave() {
      console.log('mouse leave')
      window.preventSendRemark = false
      if (this.isMovingPos) {
        this.isMovingPos = false
        this.updateItemData()
        return false
      }
      if (this.recordVisiable) return false
      this.startMoveEnable = false
      console.log('mouseEnd', this.recordVisiable)
      if (this.isBoxing) {
        // console.log('mouseEnd')
        this.markBox()
        this.isBoxing = false
      }
    },
    mouseEnd(e) {
      console.log('mouse up')
      window.preventSendRemark = false
      if (this.isMovingPos) {
        this.isMovingPos = false
        this.updateItemData()
        console.log('end: moveing')
        if (this.moveItem && this.moveItem._id) {
          let point = Object.assign({}, this.moveItem.point, this.moveItem.pos)
          delete point.pos
          this.$store.dispatch('student/upSertResponse', {_id: this.moveItem._id, point: point})
        }
        return false
      }
      if (this.recordVisiable) return false
      this.startMoveEnable = false
      console.log('mouseEnd', this.recordVisiable)
      if (this.isBoxing) {
        // console.log('mouseEnd')
        this.markBox()
        this.isBoxing = false
      } else {
        this.changeMarkType(1)
        this.markup(e)
      }
    },
    // 增加box标记
    markBox() {
      this.currentPosition = {
        ...this.currentPosition,
        width: Math.abs(this.nextPosition.offsetX - this.currentPosition.offsetX),
        height: Math.abs(this.nextPosition.offsetY - this.currentPosition.offsetY),
        left: Math.min(this.currentPosition.offsetX, this.nextPosition.offsetX),
        top: Math.min(this.currentPosition.offsetY, this.nextPosition.offsetY),
        pointType: 'box',
      }
      this.currentMark.push({point: this.currentPosition})
      this.startInputComment()
    },
    pauseEvent(e) {
      console.log('pauseEvent')
      if (e.stopPropagation) e.stopPropagation()
      if (e.preventDefault) e.preventDefault()
      e.cancelBubble = true
      e.returnValue = false
      return false
    },
    // 增加点击标识
    markPointer() {
      this.currentPosition = {
        ...this.currentPosition,
        pointType: 'point',
      }
      console.log(this.currentMark)
      this.currentMark.push({point: this.currentPosition})

      this.startInputComment()
    },
    // 打开纪录框
    startInputComment() {
      // 这次操作未完成发送
      this.sendBusyStatus = true
      this.recordVisiable = true
      this.setCurrentRemarkOptions(this.currentPosition)
      // this.changeRemarkIndex(-1)
    },
    closeRecord() {
      console.log('end record')
      this.recordVisiable = false
      if (this.sendBusyStatus) {
        // 没有发送要删除这次打点
        this.currentMark = []
      }
      this.sendBusyStatus = false
      this.startMoveEnable = false
      this.resetPosition()
    },
    showModal() {
      this.modalVisable = true
    },
    hideModal() {
      this.modalVisable = false
    },
    showDiy() {
      colorSelector.show(this.color, (rgb) => {
        // // console.log(d)
        const selectorColor = colorSelector.utils.rgb2txt(rgb)
        // console.log(selectorColor)
        this.changeColor(selectorColor)
      })
    },
    changeColor(color) {
      this.color = color
    },
    changeMarkType(type) {
      this.markType = type
      this.resetPosition()
    },
    leaveModal() {
      if (this.modalVisable) {
        this.hideModal()
      }
    },
  },
}
</script>

<style scoped>
.remark-modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  overflow: hidden;
}
.remark-mask-inner {
  position: relative;
  width: 960px;
  height: 540px;
  left: 50%;
  top: 50%;
}
.pointer-area {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.right-area {
  width: 0px;
  float: right;
}
.markitem {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  /* box-sizing: border-box; */
  position: absolute;
  z-index: 999;
  cursor: pointer;
  /* border: 2px solid transparent; */
}
.markitembox {
  position: absolute;
  z-index: 999;
  cursor: pointer;
  padding: 4px;
  border: 2px solid transparent;
}
.markitem:hover {
  border: 5px solid #777;
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  /* box-sizing: border-box; */
  transform: translate(-10px, -10px);
}
.markboxhover:hover {
  box-shadow: 0 0 20px #f00;
}
.markpos {
  z-index: 1;
  opacity: 0;
}
.innermark:hover {
  border: 5px solid #fff;
}
.innermark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* margin: 3px; */
}
.buttonlist {
  /* width: 100px;
  height: 40px; */
  display: flex;
  justify-content: space-around;
}
.item {
  width: 50px;
  cursor: pointer;
}
.record-container {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fff;
  padding: 50px 30px 30px;
  box-shadow: #333 0px 0px 20px;
}
.del-button {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.canvasfooter {
  width: 100%;
  height: 50px;
  line-height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  background-color: #fff;
}
.canvasfooter > div {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 0 30px;
  cursor: pointer;
  line-height: 30px;
}
#keyword-box {
  margin: 10px 0;
}
.canvasmodal {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 50px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #fff;
  line-height: 20px;
}
.widthlist {
  width: 100px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.widthlist .item {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: auto;
  position: relative;
}
.widthlist .icon {
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #999;
}
.icontext {
  position: absolute;
  right: -20px;
}
.colorList {
  width: 150px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
}
.colorList .colors {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #999;
  margin: 5px;
  cursor: pointer;
}
.closemodal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.dragbg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 9999;
}
.pop-color-list {
  background-color: transparent;
  width: 108px;
  height: 108px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  position: relative;
}
.pop-color-item {
  width: 36px;
  height: 36px;
  padding: 5px;
  box-sizing: border-box;
}
.pop-color-item-box {
  width: 26px;
  height: 26px;
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.markitemopacity {
  opacity: 0.8;
}
</style>
