<template>
  <div class="h100 w100 flex1" @click.stop="playVideo()" style="cursor: pointer;">
    <div class="flex_c w100 h100" style="position: absolute;" >
      <el-button type="text" class="flex_c icon-button">
        <i class="fa fa-play icon2 icon-play"></i>
      </el-button>
    </div>
    <img v-if="id" class="w100 h100" style="object-fit: contain;user-select: none;" :src="Fn.youtubeIdThumb(id)" alt="">
    <!-- <iframe class="flex1" style="border:0;margin:0;" frameborder="0" allowfullscreen :src="url"></iframe> -->
  </div>
</template>
<script>
export default {
  props: {
    id: { type: String, default: () => {} },
    start: { type: Number, default: 0},
    auto: { type: Boolean, default: false }
  },
  methods: {
    playVideo() {
      this.$store.commit('localPlay', { fullPlayUrl: `https://www.youtube.com/embed/${this.id}?autoplay=1&start=${this.start}` })
    }
  },
  mounted() {
    console.log(this.id, 'youtube mounted')
  }
}
</script>

<style scoped lang="scss">
.icon-button {
  padding: 1rem 2rem;
  border-radius: .5rem;
  .icon-play {
    color: #eee;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.5);
    .icon-play {
      color: #fff;
      transform: scale(1.2)
    }
  }
}

</style>
