<template>
  <div class="text-scroll">
    <div class="text-answer-list" v-show="currentAnswerTab !== 3">
      <template v-for="(item, index) in selectedAnswerList">
        <div :class="`colume${currentAnswerTab === 1 ? '1' : '5'} `" :id="item._id" v-if="shouldShow(item)" :key="index">
          <div :class="itemClasses(item, currentAnswerTab)" @click="onSelected(item)">
            <div class="text-list-item" :class="classFn(item)">
              <div :class="`text_area ${!flag_1 && 'full-text-area'}`">
                <div v-if="Acan.isEmpty(item.answer)">Deleted response</div>
                <div v-else class="item-content" v-for="(item, index) in colorContent(item.content)">
                  <span :style="`color:${item.color}`"
                    ><b>{{ item.choice }}: </b></span
                  ><span>{{ item.content }}</span>
                </div>
              </div>
              <div class="text-footer" v-if="flag_1">
                <StudentResponseOptBar :item="item" v-if="flag_1" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="flex_c flex1 h100" v-show="currentAnswerTab === 3" ref="chart"></div>
  </div>
</template>

<script>
import StudentResponseOptBar from './studentResponseOptBar.vue'
import {mapGetters, mapState, mapActions} from 'vuex'
export default {
  components: {StudentResponseOptBar},
  props: {
    shouldShow: {type: Function, default: () => {}},
    selectedAnswerList: {type: Array, default: []},
    classFn: {
      type: Function,
      default: () => {
        return {}
      },
    },
    itemClasses: {
      type: Function,
      default: () => {
        return {}
      },
    },
    scrollToItem: {
      type: Function,
      default: () => {
        return {}
      },
    },
    onSelected: {
      type: Function,
      default: () => {
        return {}
      },
    },
    flag_1: {type: Boolean, default: false},
  },
  data() {
    return {
      choiceData: null,
      showStatistics: true,
      isStudent: false,
      options: [],
      answerList: [],
      choices: [
        {letter: 'A', color: '#15c39a'},
        {letter: 'B', color: '#e91e63'},
        {letter: 'C', color: '#00bcd4'},
        {letter: 'D', color: '#ff9800'},
        {letter: 'E', color: '#2196f3'},
        {letter: 'F', color: '#9c27b0'},
        {letter: 'G', color: '#4caf50'},
        {letter: 'H', color: '#3f51b5'},
      ],
      isMulti: false,
      initOptions: {
        renderer: 'canvas',
      },
    }
  },
  computed: {
    ...mapGetters(['getQuestion', 'isStudentPaced', 'getPage']),
    ...mapGetters('student', ['selectedGroupMembers', 'currentPageAnswerList', 'selectMembersMap']),
    ...mapState('student', ['currentPageIndex', 'selectMembersMap']),
    ...mapState(['syncUI']),
    currentAnswerTab() {
      setTimeout(() => {
        this.scrollToItem(this.syncUI.currentAnswer)
      }, 250)
      return this.$store.getters['teacher/answerTab'](this.currentPageIndex)
    },
    isResponseShow() {
      this.scrollToItem(this.syncUI.currentAnswer)
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
    config() {
      const that = this
      const labels = this.options.map((item, i) => {
        return this.choices[i]['letter'] + ': ' + (item.on ? '✅' : '❌') + ' Selected times: ' + this.getAnswerCount(item._id)
      })
      const values = this.options.map((item) => {
        return this.getAnswerCount(item._id)
      })
      const data = {
        labels,
        datasets: [
          {
            label: '',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: values,
          },
        ],
      }
      return {
        type: 'bar',
        data,
        options: {
          scales: {
            x: {
              ticks: {
                color: function (context) {
                  if (context.tick) {
                    return that.choices[context.index]['color']
                  }
                },
                font: function (context) {
                  if (context.tick) {
                    return {
                      weight: 'bold',
                    }
                  }
                },
              },
            },
            y: {
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: false,
            tooltip: {
              displayColors: false,
              callbacks: {
                label: (context) => {
                  //return this.options[context.dataIndex].val + ' ' + context.formattedValue
                  return (
                    //https://tw.piliapp.com/symbol/square/
                    '▋' + this.options[context.dataIndex].val.substr(0, 40) + (this.options[context.dataIndex].val.length > 40 ? '...' : '')
                  )
                },
              },
            },
          },
        },
      }
    },
  },
  watch: {
    getQuestion() {
      this.options = this.getQuestion.options
      this.initChart()
    },
    selectMembersMap() {
      this.initChart()
    },
    currentPageAnswerList() {
      this.initChart()
    },
    syncUI: {
      handler(o) {
        let answer = null
        this.selectedAnswerList.forEach((a, idx) => {
          if (o.currentAnswer == a._id) {
            answer = a
          }
        })
        if (answer && answer._id) {
          this.scrollToItem(answer._id)
        }
      },
      deep: true,
    },
  },
  created() {
    const {options, multi} = this.getQuestion
    this.options = options
    this.isMulti = multi
  },
  mounted() {
    ;(this.isStudent = this.$route.name === 's'), this.initChart()
  },
  beforeDestroy() {
    this.myChart.destroy()
    console.log('destroy', this.myChart)
  },
  methods: {
    initChart() {
      this.$refs.chart.querySelector('canvas')?.remove()
      const dom = document.createElement('canvas')
      dom.className = 'flex1'
      this.myChart = new Chart(dom, this.config, [{name: 'test', handler(chart) {}}])
      this.$refs.chart.appendChild(dom)
    },
    getAnswerCount(value) {
      let count = 0
      const {isMulti} = this
      for (const o of this.selectedAnswerList) {
        let answer = o.answer
        if (isMulti) {
          if (!Array.isArray(answer)) answer = [answer]
          if (answer.includes(value)) count++
        } else {
          if (value == answer) count++
        }
      }
      return count
    },
    pageChange(value) {
      this.showStatistics = value == 2
    },
    colorContent(item) {
      const _item = item.split('\n')
      const choices = []
      _item.forEach((i) => {
        if (i) {
          choices.push({
            choice: i.substr(0, 1),
            content: i.substr(2),
            color: this.choices.find((el) => {
              return el.letter == i.substr(0, 1)
            })?.color,
          })
        }
      })
      return choices
    },
  },
}
</script>
<style scoped>
.text-scroll canvas {
  max-height: 90%;
}
</style>
