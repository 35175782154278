<template>
  <div class="previewBtn" @click="showPreview">
     <svg t="1642930667713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11701" width="20" height="20"><path d="M430.08 558.08L153.6 834.56V665.6c0-15.36-10.24-25.6-25.6-25.6s-25.6 10.24-25.6 25.6v230.4c0 7.68 2.56 12.8 7.68 17.92 5.12 5.12 10.24 7.68 17.92 7.68h230.4c15.36 0 25.6-10.24 25.6-25.6s-10.24-25.6-25.6-25.6H189.44l276.48-276.48-35.84-35.84zM913.92 110.08c-5.12-5.12-12.8-7.68-17.92-7.68h-230.4c-15.36 0-25.6 10.24-25.6 25.6s10.24 25.6 25.6 25.6h168.96L558.08 430.08l35.84 35.84L870.4 189.44V358.4c0 15.36 10.24 25.6 25.6 25.6s25.6-10.24 25.6-25.6V128c0-7.68-2.56-12.8-7.68-17.92z" p-id="11702" fill="#fff"></path></svg>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
export default {
  props: {
    noSync: { type: Boolean, default: false },
    urlBg: { // 背景图片
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    previewPress: {
      type: Function,
      default: null
    }
  },
  methods: {
    ...mapActions("teacher", ["setCurrentPreviewData"]),
    showPreview() {
      if(this.previewPress) return this.previewPress(this.noSync)
      this.setCurrentPreviewData({ type: 'image', urlBg: this.urlBg, url: this.url, noSync: this.noSync })
    }
  }
}
</script>
<style scoped>
.previewBtn{
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  /* background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 50px 50px;
  background-image: url(../../assets/picture/previewBig.png); */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 5px;
  box-sizing: border-box;
  z-index: 95;
}
.previewBtn:hover{
  background-color: salmon;
}
</style>