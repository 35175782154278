<template>
  <div>
    <div class="upload-file" >
      <input
        class="upload-file"
        type="file"
        :accept="accept"
        ref="uploadFile"
        @change="onUpload"/>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => null
    },
    accept: {
      type: String,
      default: '.pdf, application/pdf,audio/*,video/*,image/*'
    },
    onlyGetFile: {
      type: Boolean,
      default: false // 是否只得到本地file，单独做上传处理，默认false
    }
  },
  methods: {
    async onUpload(e) {
      const file = e.target.files[0]
      if(this.onlyGetFile) {
        this.onSuccess(file)
        this.end()
        return
      }
      // return
      this.onSuccess(file, await Fn.fileUpLoadUi(file))
    },
    cancel() {
      this.end()
    },
    end() {
      this.$refs.uploadFile.value = ''
    }
  }
}
</script>
<style scoped>
.upload-file{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
</style>