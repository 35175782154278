<template>
  <div class="base64img" :style="`background-image:url(${Fn.hashToUrl(url)})`">
    <slot />
    <previewButton :url="url" v-if="showPreview" :noSync="noSync"/>
  </div>
</template>
<style scoped>
.base64img{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 0;left: 0;
}
</style>
<script>
import previewButton from './common/previewButton.vue'
export default {
  components: { previewButton },
  props: {
    noSync: { type: Boolean, default: false },
    url: {
      type: String,
      default: ''
    },
    showPreview: {
      type: Boolean,
      default: false
    }
  },
}
</script>