<template>
  <div class="flex_l" v-if="user">
    <studentAvatar :student="user" />
    <div class="flex_v">
      <div class="flex1 flex_ac theme"><b>{{data.nickname || user.nickname}}</b></div>
      <div class="flex_l c_grey" v-show="!data.noLast"><span class="footer-small">{{new Date(data.last).toLocaleString()}}</span></div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import studentAvatar from './studentAvatar.vue'
export default {
  components: { studentAvatar },
  props: {
    data: { type: Object, required: true }
  },
  computed: {
    ...mapState(['userList']),
    user() {
      if (!this.data.uid) return this.data
      if (!this.userList[this.data.uid]) this.$store.dispatch('userInfo', this.data.uid)
      return this.userList[this.data.uid]
    }
  },
  // methods: {
  //   ...mapActions(['userInfo']),
  // }
}
</script>
<style>
  .footer-small {
    padding-top:0.2rem;
    font-size: 0.65rem;
  }
</style>
