<template>
  <div class="res-and-student" >
    <div class="res-inner">
      <div class="dash-pad-hidden" v-if="!isStudent">
        <dashGroupsSelect :changeFn="groupChange"/>
      </div>
      <div class="clearallstudent" :class="{active: selectedMembers.length > 0}" @click="clearallstudent">Clear all</div>
      <ul class="res-list">
        <template v-for="(item) in filterStudents()">
          <li class="student-list-item" :key="item._id" @click="selectUsers(item)">
            <i class="el-icon-success ans-status" v-if="selectedMembers.includes(item._id)"/>
            <div class="ans-status nos" v-else/>
            <studentAvatar :student="item">
              <div v-if="upAnswerUserList[item._id]" class="answered"/>
            </studentAvatar>
            <div class="user-name" :title="item.nickname">{{item.nickname}}</div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import dashGroupsSelect from './dashGroupsSelect.vue'
import studentAvatar from '../common/studentAvatar.vue'
export default {
  data() {
    return {
      isStudent: location.href.indexOf('/s/') > -1
    }
  },
  components: { dashGroupsSelect, studentAvatar },
  computed: {
    ...mapState(['session']),
    ...mapGetters(['getPageId']),
    ...mapGetters('student', ['selectedMembers', 'selectedGroup', 'groupMembers', 'currentPageAnswerList']),
    ...mapState('student', ['currentPageIndex']),
    ...mapState('teacher', ['groups', 'rooms']),
    ...mapState({
      allRemarks: state => state.remark.allRemarks,
    }),
    upAnswerUserList() {
      const list = {}
      this.currentPageAnswerList.map(v => {
        list[v.uid] = 1
      })
      return list
    },
    currentComments() {
      let list = []
      if(this.getPageId && this.allRemarks.length > 0) {
        list = this.allRemarks.filter(
          item => item.page_id === this.getPageId
        );
      }
      list.reverse()
      return list;
    },
  },
  mounted() {
    console.warn(this.currentPageAnswerList)
  },
  methods: {
    ...mapMutations('student', ['setSelectMembers', 'setSelectGroup', 'toggleSelectMember']),
    
    filterStudents() {
      if (this.$route.name === 's') return this.groupMembers
      if (this.selectedGroup) return this.rooms.members.filter(v => v.group === this.selectedGroup)
      return this.rooms.members.filter(v => this.rooms.attend.includes(v._id))
    },
    async groupChange(id) {
      const arr = [this.currentPageIndex, id]
      this.setSelectGroup(arr)
      if (this.session.status === 'live') App.service('auth').patch('syncTeacher', { commit: 'student/setSelectGroup', to: 'teacher', data: arr })
    },
    async selectUsers(item) {
      console.log(item)
      this.toggleSelectMember(item._id)
      if (this.session.status === 'live') App.service('auth').patch('syncTeacher', { commit: 'student/setSelectMembers', to: 'teacher', data: [this.currentPageIndex, this.selectedMembers] })
    },
    async clearallstudent() {
      this.setSelectMembers([this.currentPageIndex, []])
      if (this.session.status === 'live') App.service('auth').patch('syncTeacher', { commit: 'student/setSelectMembers', to: 'teacher', data: [this.currentPageIndex, []] })
    }
  }
}
</script>
<style scoped>
  
  .res-inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #ebe7e7;
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 4px;
  }
  .user-icon {
    width: 52px;
    height: 52px;
    border-radius: 26px;
    margin-right: 11px;
    line-height: 52px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    background-color: #afafaf;
    position: relative;
  }
  .user-icon.online{
    background-color: red;
  }
  .student-icon{
    width: 44px;
    height: 44px;
    border-radius: 22px;
    line-height: 44px;
  }
  .user-name {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
    opacity: 1;
    text-align: left;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-time {
    font-size: 10px;
    line-height: 14px;
  }
  .ans-detail{
    flex: 1;
    background-color: rgba(228, 228, 228, 1);
    border-radius: 6px;
  }
  .notSelected{
    opacity: 0.3;
  }
  .ans-status{
    width: 21px;
    height: 21px;
    margin-right: 14px;
    box-sizing: border-box;
  }
  .ans-status.nos{
    border-radius: 50%;
    border: 1px solid #D8D8D8;
    background-color: #fff;
  }
  .showResButoon{
    width: 120px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(21, 195, 154, 1);
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    position: relative;
    margin: 0 auto 10px auto;
  }
  .answered{
    background-image: url(../../assets/picture/answered.png);
    background-size: 24px 24px;
    /* background-position: top 5px right 5px; */
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -6px;
    right: -6px;
    transform: scale(1.5);
  }
  .clearallstudent.active{
    color: #FF1A0E;
  }
  .clearallstudent{
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    color: grey;
    cursor: pointer;
    text-align: right;
    padding-right: 20px;
  }
</style>
