<template>
  <div class="file-icon" :class="getIconClass(item)" @click="previewPdf"></div>
</template>
<script>
import {mapActions} from 'vuex'
export default {
  props: {
    className: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isPdf: false
    }
  },
  created() {
    const name = this.item.fileName
    this.isPdf = name.indexOf('.pdf') > -1
  },
  methods: {
    ...mapActions("teacher", ["setCurrentPreviewData"]),
    getIconClass(name) {
      if(!name) return 'file'
      ext = name.split(':')[1]
      if(ext === 'pdf') return 'pdf'
      if(['doc', 'docx'].includes(ext)) return 'word'
      return 'file'
    },
    previewPdf() {
      if(this.isPdf) {
        const pdfUrl = this.item.link
        this.setCurrentPreviewData({ type: 'pdf', url: pdfUrl })
      }
    }
  }
}
</script>
<style scoped>

</style>