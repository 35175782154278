<template>
  <div class="dashpptlist" v-if="session.pages" >
    <div class="dash-left" @click="prev"></div>
    <div class="inner-swiper" ref="innerSwiper">
      <div v-for="(item, index) in session.pages" :key="index"
            :ref="currentPageIndex === index ? 'activeRef': ''"
            :tabindex="currentPageIndex === index ? '0' : ''"
            @click="changeToPage(index, item._id)"
            class="ppt-list-item-outer">
        <div v-bind:class=" currentPageIndex == index ? 'ppt_content image_parent_focus' : 'ppt_content '">
          <div class="image_parent" :style="`background-image:url(${Fn.hashToUrl(item.pic || item.url)})`"></div>
          <i class="index-tag">{{index+1}}/{{session.pages.length}}</i>
        </div>
      </div>
    </div>
    <div class="dash-left dash-right" @click="next"></div>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
export default {
  props: {
    changePage: {
      type: Function
    }
  },
  computed: {
    ...mapState(['session']),
    ...mapState({
      currentPageIndex: state => state.student.currentPageIndex,
    })
  },
  mounted() {
    this.focus()
  },
  watch: {
    currentPageIndex() {}
  },
  methods: {
    next() {
      this.moveSlow(this.$refs.innerSwiper.scrollLeft, this.$refs.innerSwiper.offsetWidth)
    },
    prev() {
      this.moveSlow(this.$refs.innerSwiper.scrollLeft, -this.$refs.innerSwiper.offsetWidth)
    },
    changeToPage(index, page_id) {
      Acan.url('id', page_id)
      this.changePage(index + 1)
    },
    focus() {
      this.$nextTick(() => {
        if(this.$refs.activeRef) {
          this.moveSlow(0, this.$refs.activeRef[0].offsetLeft - this.$refs.innerSwiper.offsetWidth / 2)
        }
      });
    },
    moveSlow(distance, total) {
      this.$refs.innerSwiper.scrollLeft = distance + total
      return
      // 正向滚动 和 反向滚动
      if (this.lastItemIndex < this.itemIndex) {
        // 每隔1毫秒移动一小段距离，直到移动至目标至为止，反之亦然
        if (distance < total) {
          distance += step
          this.$refs.idSwiperImg.scrollLeft = distance
          setTimeout(() => {
            this.moveSlow(distance, total, step)
          }, 1)
        } else {
          this.$refs.idSwiperImg.scrollLeft = total
        }
      } else if (this.lastItemIndex > this.itemIndex) {
        if (distance > total) {
          distance -= step
          this.$refs.idSwiperImg.scrollLeft = distance
          setTimeout(() => {
            this.moveSlow(distance, total, step)
          }, 1)
        } else {
          this.$refs.idSwiperImg.scrollLeft = total
        }
      }
    }
  },
}
</script>