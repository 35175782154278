<template>
  <div class="flex_c h100 flex1" v-if="url">
    <video ref="audio" class="w100 flex1" style="height:50px;"
      :src="Fn.hashToUrl(url)" preload="metadata" controls controlslist="nodownload" muted/>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    }
  }
}
</script>
