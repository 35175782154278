<template>
  <div class="text-scroll">
    <div class="text-answer-list">
      <template v-for="(item, index) in selectedAnswerList">
        <div :class="`colume${currentAnswerTab === 1 ? '1' : '5'} `" :id="item._id" v-if="shouldShow(item)" :key="index">
          <div :class="itemClasses(item, currentAnswerTab)" @click="onSelected(item)">
            <div class="text-list-item" :class="classFn(item)">
              <div :class="`text_area ${currentAnswerTab === 1 ? '' : 'columText'} ${!flag_1 && 'full-text-area'}`">
                <div class="textinner item-content" :class="item.content ? '' : 'deleted'">
                  {{ getText(item) }}
                </div>
                <span class="text_static" v-if="flag_1 && isTextList">
                  {{ index + 1 + ' of ' + selectedAnswerList.length }}
                </span>
              </div>
              <div class="text-footer" v-if="flag_1">
                <StudentResponseOptBar :item="{...item, title: getText(item)}" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import StudentResponseOptBar from './studentResponseOptBar.vue'
import {mapGetters, mapState, mapActions} from 'vuex'
export default {
  components: {StudentResponseOptBar},
  props: {
    shouldShow: {type: Function, default: () => {}},
    selectedAnswerList: {type: Array, default: []},
    classFn: {
      type: Function,
      default: () => {
        return {}
      },
    },
    itemClasses: {
      type: Function,
      default: () => {
        return {}
      },
    },
    onSelected: {
      type: Function,
      default: () => {
        return {}
      },
    },
    scrollToItem: {
      type: Function,
      default: () => {
        return {}
      },
    },
    flag_1: {type: Boolean, default: true},
  },
  computed: {
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    ...mapState(['syncUI']),
    ...mapGetters(['isStudentPaced', 'getPage']),
    currentAnswerTab() {
      setTimeout(() => {
        this.scrollToItem(this.syncUI.currentAnswer)
      }, 250)
      return this.$store.getters['teacher/answerTab'](this.currentPageIndex)
    },
    isResponseShow() {
      this.scrollToItem(this.syncUI.currentAnswer)
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
    isTextList() {
      // 计算是否显示统计 1 of n
      const one = this.selectedAnswerList[0]
      const frr = _.filter(this.selectedAnswerList, {uid: one.uid})
      if (frr.length > 1 && frr.length === this.selectedAnswerList.length) return true
      return false
    },
  },
  data() {
    return {
      isTextChanging: false,
      isStudent: false,
      changeUser: '', //当前是哪个item发生了变化
      changeItemId: '', //当前是哪个item发生了变化
      sortValue: 1,
    }
  },
  created() {},
  mounted() {
    this.isStudent = this.$route.name === 's'
  },
  watch: {
    syncUI: {
      handler(o) {
        let answer = null
        this.selectedAnswerList.forEach((a, idx) => {
          if (o.currentAnswer == a._id) {
            answer = a
          }
        })
        if (answer && answer._id) {
          this.scrollToItem(answer._id)
        }
      },
      deep: true,
    },
  },
  methods: {
    getText(item) {
      if (!item.content) return 'Deleted Response'
      if (this.isTextChanging) {
        if (item.uid == this.changeUser && item._id == this.changeItemId) {
          return item.content + '....'
        }
      }
      return item.content
    },
  },
}
</script>

<style scoped>
.item-content {
  padding-right: 2.5rem;
}
</style>
