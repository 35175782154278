<template>
  <div
    v-show="!isSleep && syncUI.fullPlayUrl"
    id="fullPlay"
    class="flex1"
    style="position: fixed; z-index: 4000; top: 0; left: 0; right: 0; bottom: 0; background: black">
    <!-- <audio v-show="isLocal" class="flex1" preload="auto" controls controlslist="nodownload"></audio> -->
    <video v-show="isLocal" class="flex1" preload="auto" controls autoplay controlslist="nodownload"></video>
    <iframe
      v-show="ytUrl"
      id="ytplayer"
      class="flex1"
      type="text/html"
      frameborder="0"
      :src="ytUrl"
      @load="initYoutube"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    <el-button style="position: absolute; right: 1rem; top: 1rem" icon="el-icon-close" circle @click="$store.dispatch('fullPlayClose')"></el-button>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
export default {
  computed: {
    ...mapState(['syncUI']),
    isLocal() {
      return this.syncUI.fullPlayUrl && !this.syncUI.fullPlayUrl?.includes('youtube')
    },
    ytUrl() {
      if (!this.syncUI.fullPlayUrl?.includes('youtube')) return ''
      const u = new URL(this.syncUI.fullPlayUrl)
      u.searchParams.set('enablejsapi', '1')
      u.searchParams.set('origin', location.origin)
      return u.href
    },
  },
  watch: {
    'syncUI.fullPlayUrl': {
      handler(o, v) {
        if (!o) return (document.querySelector('#fullPlay video').src = '')
        if (o === v) return
        this.loading = !!o
        console.warn(o, v, this.loading, this.isLocal)
        if (!o) return
        if (this.isLocal) this.initLocal()
        document.body.focus()
        this.$forceUpdate()
      },
      deep: true,
    },
    'syncUI.fullPlayVolume': {
      handler(o) {
        if (this.loading || !this.syncUI.fullPlayUrl) return // console.warn('fullPlayVolume', o, this.loading)
        console.warn(o, 'on volume')
        this.volume()
      },
      deep: true,
    },
    'syncUI.fullPlaySeek': {
      async handler(o) {
        if (this.loading || !this.syncUI.fullPlayUrl) return // console.warn('fullPlaySeek', o, this.loading)
        if (this.isLocal) this.videoDom.currentTime = o
        else {
          this.ytPlayer.seekTo(o, true)
        }
      },
      deep: true,
    },
    'syncUI.fullPlayPaused': {
      handler(o) {
        if (this.loading || !this.syncUI.fullPlayUrl) return //console.warn('fullPlayPaused', o, this.loading)
        o ? this.pause() : this.play()
      },
      deep: true,
    },
  },
  data() {
    return {
      lastUrl: '',
      lastTime: 0,
      videoDom: null,
      loading: false,
      pushing: false,
      isSleep: true,
      isVolumeChanging: false,
      volumeChangeTimeout: null,
      ytPlayer: null,
      ytPlayerI: null,
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },
  async mounted() {
    window._st2 = this
    await sleep(1000)
    this.isSleep = false
    this.lastUrl = this.syncUI.fullPlayUrl
    window.addEventListener('message', this.onMessage)
  },
  methods: {
    ...mapActions(['setSyncUI']),
    async onMessage(e) {
      if (!e) return
      //if (this.$route.name !== 't') return console.warn('no project')
      if (e.origin !== 'https://www.youtube.com') return
      const o = JSON.parse(e.data)
      if (!o?.info) return
      const post = {}
      if (o.info.currentTime) {
        const currentTime = parseInt(o.info.currentTime)
        if (this.lastTime === currentTime) return
        this.lastTime = currentTime
        post.fullPlayCurrentTime = currentTime
      }
      if (o.info.volume) {
        if (this.pushing) return
        this.pushing = true
        post.fullPlayVolume = o.info.muted ? 0 : o.info.volume
        setTimeout(() => {
          this.pushing = false
        }, 300)
      }
      this.setSyncUI(post)
    },
    initLocal() {
      this.loading = true
      console.warn('initLocal', this.syncUI.fullPlayUrl)
      const dom = document.querySelector('#fullPlay video')
      if (!dom) return setTimeout(() => this.initLocal(), 100)
      // if (!this.syncUI.fullPlayUrl) return
      window.videoDom = this.videoDom = dom
      dom.src = this.syncUI.fullPlayUrl
      dom.onloadeddata = () => {
        this.loading = false
        this.volume()
        if (!this.syncUI.fullPlayPaused) this.play()
      }
      dom.ontimeupdate = (e) => {
        const currentTime = parseInt(dom.currentTime)
        if (this.lastTime === currentTime) return
        console.log('ontimeupdate', currentTime, dom.currentTime, this.lastTime, videoDom.duration)
        this.lastTime = currentTime
        this.setSyncUI({fullPlayCurrentTime: currentTime, fullPlayDuration: videoDom.duration, fullPlayPaused: false})
      }

      dom.onpause = (e) => {
        this.setSyncUI({fullPlayPaused: true})
      }

      dom.onvolumechange = (e) => {
        if (this.isVolumeChanging) {
          clearTimeout(this.volumeChangeTimeout)
        }

        this.isVolumeChanging = true

        this.volumeChangeTimeout = setTimeout(() => {
          this.isVolumeChanging = false
          const volume = dom.muted ? 0 : dom.volume * 100
          if (Math.abs(volume - this.syncUI.fullPlayVolume) > 5) {
            this.setSyncUI({fullPlayVolume: volume})
          }
        }, 500) // Debounce delay in milliseconds
      }
    },
    async initYoutube() {
      if (!this.ytUrl) return console.warn('no ytUrl', this.ytUrl)
      if (window.ytPlayer) return console.warn('has init', this.syncUI.fullPlayUrl, this.ytUrl)
      this.loading = true
      console.warn('initYoutube', this.ytUrl, this.loading)
      // if (this.ytPlayer) return this.ytPlayer.loadVideoByUrl(o.url, 0)
      window.ytPlayer = this.ytPlayer = new YT.Player('ytplayer', {
        host: 'https://www.youtube.com',
        events: {
          onReady: async (e) => {
            console.warn('onReady', e)
            if (!e) return console.warn(e, 'onReady error')
            this.ytPlayerI = e.target
            await sleep(500)
            const duration = this.ytPlayer.getDuration()
            this.setSyncUI({fullPlayDuration: duration})
            this.ytPlayer.mute()
            if (!this.syncUI.fullPlayPaused) this.ytPlayer.playVideo()
            this.loading = false
            console.warn('ytplayer onReady', this.loading, duration)
          },
          onStateChange: (a) => {
            this.loading = false
            this.setSyncUI({fullPlayPaused: a.data === 2})
          },
          onApiChange: () => {
            console.warn('ytplayer onApiChange')
          },
          onError: (e) => {
            console.warn(e, 'ytplayer onError')
          },
        },
        playerVars: {autoplay: 1, controls: 0, origin: location.origin},
      })
    },
    volume() {
      if (this.isLocal) {
        document.querySelector('#fullPlay video').volume = (this.syncUI.fullPlayVolume ?? 0) / 100
        if (this.syncUI.fullPlayVolume) {
          document.querySelector('#fullPlay video').muted = false
        }
        //this.play()
        return
      }
      if (this.syncUI.fullPlayVolume > 0 && this.ytPlayer.isMuted()) this.ytPlayer.unMute()
      this.ytPlayer.setVolume(parseInt(this.syncUI.fullPlayVolume))
      //this.ytPlayer.playVideo()
    },
    play() {
      if (!this.syncUI.fullPlayUrl) return
      console.warn('fullPlay.play', this.isLocal)
      if (this.isLocal) document.querySelector('#fullPlay video').play()
      else this.ytPlayer?.playVideo()
    },
    pause() {
      this.setSyncUI({fullPlayPaused: true})
      console.warn('fullPlay.pause', this.isLocal)
      if (this.isLocal) document.querySelector('#fullPlay video').pause()
      else this.ytPlayer?.pauseVideo()
    },
    stop() {
      if (this.isLocal) document.querySelector('#fullPlay video').src = null
      else this.ytPlayer?.stopVideo()
    },
    playTo(time) {
      if (this.isLocal) document.querySelector('#fullPlay video').currentTime = time
      else {
        this.ytPlayerI.seekTo(time)
        // this.ytPlayer.playVideo()
      }
    },
  },
}
</script>
