<template>
  <div class="student-list-item select-header" v-if="rooms.groups.length > 0">
    <el-select v-model="currentGroupId" placeholder="All" @change="changeGroup" style="background: #fff">
      <el-option label="ALL" value=""></el-option>
      <el-option
        v-for="item in rooms.groups"
        :key="item._id"
        :label="item.name"
        :value="item._id">
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  data() {
    return {
      currentGroupId: '',
    }
  },
  props: {
    changeFn: {
      type: Function,
      default: () => null
    }
  },
  watch: {
    selectedGroup(val) {
      this.currentGroupId = val
    }
  },
  computed: {
    ...mapGetters('student', ['selectedGroup']),
    ...mapState('teacher', ['rooms']),
  },
  methods: {
    changeGroup(id) {
      return this.changeFn(id)
    },
  },
  mounted() {
    this.currentGroupId = this.selectedGroup
  }
}
</script>
<style scoped>
  .res-inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #ebe7e7;
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 4px;
  }
</style>