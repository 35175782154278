<template>
  <div class="remarkImage flex_c" ref="remarkImageContent">
    <div class="">
      <pptcontent :url="Fn.hashToUrl(getPageById(item.page).pic || getPageById(item.page).url)" />
    </div>
    <div class="remarkImageBg" ref="remarkImageBg">
      <div slot="reference" v-if="item.point.pointType !== 'box'" class="markitem" :style="`top:${item.point.top}px;left:${item.point.left}px;`">
        <div class="markitemopacity innermark" :style="`background-color:${item.point.background}; `" />
      </div>
      <div
        slot="reference"
        v-else-if="item.point.pointType === 'box'"
        class="markitembox"
        :style="`top:${item.point.top - 6}px; left:${item.point.left - 6}px;`">
        <div class="markitemopacity" :style="`width:${item.point.width}px;height:${item.point.height}px;border: 2px solid ${item.point.background}`" />
      </div>
    </div>

    <preview-button :previewPress="previewComment" :noSync="noSync" />
  </div>
</template>
<script>
import pptcontent from '@/components/pptcontent.vue'
import PreviewButton from '@/components/common/previewButton.vue'
import {mapGetters, mapActions} from 'vuex'
export default {
  components: {pptcontent, PreviewButton},
  props: {
    noSync: {type: Boolean, default: false},
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['getPage', 'getPageById']),
  },
  data() {
    return {
      ratio: 1,
    }
  },
  mounted() {
    this.resizeRemark()
  },
  methods: {
    ...mapActions('teacher', ['setCurrentPreviewData']),
    previewComment() {
      this.setCurrentPreviewData({noSync: this.noSync, type: 'comment', url: this.item})
    },
    async resizeRemark() {
      const {remarkImageContent, remarkImageBg} = this.$refs
      console.log('resizeMark')
      if (!remarkImageContent || remarkImageContent.clientWidth === 0 || !remarkImageBg) {
        await sleep(100)
        return this.resizeRemark()
      }
      const w = remarkImageContent.clientWidth
      this.ratio = w / 960
      remarkImageBg.style.transform = `translateY(-50%) translateX(-50%) scale(${this.ratio})`
    },
  },
}
</script>
<style scoped></style>
