<template>
  <div class="keyboard">
    <el-input
      ref="inputRef"
      :value="value"
      v-bind="$attrs"
      class="k-input"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
      @keypress.native="handlePress"
      @input="handleInput">
    </el-input>
    <template v-if="showButtons">
      <el-row>
        <el-col :span="4">
          <el-button type="primary" size="mini" @click="onToggleClick">π</el-button>
        </el-col>
        <el-col :span="20" class="buttons" v-if="showKeyboard">
          <el-tooltip v-for="(s, i) in supersubSymbols" :content="`Click and type to add ${i ? 'subscripts' : 'superscripts'}`">
            <el-button size="mini" :type="s.active ? 'primary' : ''" @click="onSupersubSymbolsClick(s)">{{ s.label }}</el-button>
          </el-tooltip>
          <el-button v-for="n in otherSymbols" size="mini" @click="onOtherSymbolsClick(n)">{{ n }}</el-button>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {type: String},
  },
  data() {
    return {
      showButtons: false,
      showKeyboard: false,
      typing: false,
      processi: 0,
      intervalId: 0,
      supersubSymbols: [
        {active: false, label: 'Xⁿ'},
        {active: false, label: 'Xᵤ'},
      ],
      otherSymbols: [
        'X²',
        'X³',
        'X⁴',
        '√',
        '+',
        '-',
        '×',
        '÷',
        '/',
        '±',
        '∑',
        '!',
        'log()',
        'ln()',
        'π',
        'º',
        "'",
        "''",
        'sin()',
        'cos()',
        'tan()',
        'sin⁻¹()',
        'cos⁻¹()',
        'tan⁻¹()',
        '()',
        '[]',
        '{}',
        '||',
        'ƒ’(x)',
        'ƒ’’(x)',
        '∫',
        '=',
        '≠',
        '≤',
        '<',
        '>',
        '≥',
        '∞',
        'α',
        'β',
        'δ',
        'ε',
        'θ',
        'ω',
        'γ',
      ],
      supers: [
        {key: '1', val: '¹'},
        {key: '2', val: '²'},
        {key: '3', val: '³'},
        {key: '4', val: '⁴'},
        {key: '5', val: '⁵'},
        {key: '6', val: '⁶'},
        {key: '7', val: '⁷'},
        {key: '8', val: '⁸'},
        {key: '9', val: '⁹'},
        {key: '0', val: '⁰'},
        {key: '(', val: '⁽'},
        {key: ')', val: '⁾'},
        {key: '-', val: '⁻'},
        {key: '+', val: '⁺'},
        {key: '=', val: '⁼'},
        {key: 'I', val: 'ⁱ'},
        {key: 'N', val: 'ⁿ'},
        {key: 'i', val: 'ⁱ'},
        {key: 'n', val: 'ⁿ'},
      ],
      subs: [
        {key: '1', val: '₁'},
        {key: '2', val: '₂'},
        {key: '3', val: '₃'},
        {key: '4', val: '₄'},
        {key: '5', val: '₅'},
        {key: '6', val: '₆'},
        {key: '7', val: '₇'},
        {key: '8', val: '₈'},
        {key: '9', val: '₉'},
        {key: '0', val: '₀'},
        {key: '(', val: '₍'},
        {key: ')', val: '₎'},
        {key: '-', val: '₋'},
        {key: '+', val: '₊'},
        {key: '=', val: '₌'},
        {key: 'E', val: 'ₑ'},
        {key: 'R', val: 'ᵣ'},
        {key: 'Y', val: 'ᵧ'},
        {key: 'U', val: 'ᵤ'},
        {key: 'I', val: 'ᵢ'},
        {key: 'O', val: 'ₒ'},
        {key: 'A', val: 'ₐ'},
        {key: 'X', val: 'ₓ'},
        {key: 'V', val: 'ᵥ'},
        {key: 'B', val: 'ᵦ'},
        {key: 'e', val: 'ₑ'},
        {key: 'r', val: 'ᵣ'},
        {key: 'y', val: 'ᵧ'},
        {key: 'u', val: 'ᵤ'},
        {key: 'i', val: 'ᵢ'},
        {key: 'o', val: 'ₒ'},
        {key: 'a', val: 'ₐ'},
        {key: 'x', val: 'ₓ'},
        {key: 'v', val: 'ᵥ'},
        {key: 'b', val: 'ᵦ'},
      ],
    }
  },
  computed: {},
  watch: {},
  created() {},
  destroyed() {
    clearInterval(this.intervalId)
  },
  mounted() {
    if (this.$attrs.focus) {
      this.typing = true
      if (this.$attrs.type == 'textarea') {
        setTimeout(()=>{
          this.$refs.inputRef.$refs.textarea.focus()
        }, 100)
      } else {
        setTimeout(()=>{
          this.$refs.inputRef.$refs.input.focus()
        }, 100)
      }
    }
    this.intervalId = setInterval(() => {
      this.process()
    }, 100)
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
    process() {
      if (!this.typing && this.processi > 3) {
        this.hideKeyboard()
        this.processi = 0
      } else {
        this.processi++
      }
    },
    hideKeyboard() {
      if (this.showButtons) {
        this.showButtons = false
        this.showKeyboard = false
        this.deactiveSupersubSymbols()
        this.$emit('blur')
      }
    },
    onBlur(e) {
      this.processi = 0
      this.typing = false
    },
    onChange(e) {
      this.$emit('change')
    },
    onFocus(e) {
      this.typing = true
      this.showButtons = true
      this.$emit('focus', e)
    },
    focusInput() {
      this.$refs['inputRef'].focus()
      this.$emit('focus')
    },
    onToggleClick() {
      this.focusInput()
      this.showKeyboard = !this.showKeyboard
      if (!this.showKeyboard) {
        this.deactiveSupersubSymbols()
      }
    },
    deactiveSupersubSymbols() {
      this.supersubSymbols.forEach((smb) => {
        smb.active = false
      })
    },
    onSupersubSymbolsClick(s) {
      this.focusInput()
      this.supersubSymbols.forEach((smb) => {
        if (smb == s) {
          smb.active = !smb.active
        } else {
          smb.active = false
        }
      })
    },
    onOtherSymbolsClick(symbol) {
      const target = this.$refs.inputRef.$refs.textarea ? this.$refs.inputRef.$refs.textarea : this.$refs.inputRef.$refs.input
      let value = this.value ? this.value + symbol : symbol
      if (target && typeof target.selectionStart == 'number' && typeof target.selectionEnd == 'number') {
        const start = target.selectionStart
        const end = target.selectionEnd
        value = this.value.slice(0, start) + symbol + this.value.slice(end)
      }

      this.focusInput()
      this.deactiveSupersubSymbols()
      this.handleInput(value)
    },
    replaceSymbles(e, val) {
      if (typeof e.target.selectionStart == 'number' && typeof e.target.selectionEnd == 'number') {
        const start = e.target.selectionStart
        const end = e.target.selectionEnd
        const value = e.target.value.slice(0, start) + val + e.target.value.slice(end)

        this.handleInput(value)

        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = start + 1
        }, 50)
      }
    },
    handlePress(e) {
      if (this.supersubSymbols[0].active) {
        e.preventDefault()
        const needle = this.supers.filter((s) => {
          return s.key == e.key
        })
        if (needle.length) {
          this.replaceSymbles(e, needle[0].val)
        }
      }

      if (this.supersubSymbols[1].active) {
        e.preventDefault()
        const needle = this.subs.filter((s) => {
          return s.key == e.key
        })
        if (needle.length) {
          this.replaceSymbles(e, needle[0].val)
        }
      }
    },
  },
}
</script>
<style scoped lang="sass">
.keyboard
  .k-input
    margin-bottom: 0.5rem
  button
    margin-bottom: 0.2rem
.buttons
  max-height: 6.6rem
  overflow-y: scroll
  button
    &:first-child
      margin-left: 10px
</style>
