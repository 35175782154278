<template>
  <div class="mediaFour" v-if="materialSelectList.length > 0">
    <div v-for="(item, i) in materialSelectList" :key="i" class="mediaFourItem" :class="{mediaFourItemfull: materialSelect.length === 1}">
      <ItemChild :item="item" isPreviewBig />
      <div class="btns" v-if="item">
        <el-tooltip class="item" effect="dark" content="Play on projector" placement="bottom">
          <el-button
            :type="syncUI.fullPlayUrl === item.url ? 'primary' : ''"
            v-if="['mp3', 'audio', 'video', 'iframe', 'youtube'].includes(item.type) && $route.name === 'd'"
            icon="el-icon-monitor"
            :disabled="isStudentPaced"
            circle
            @click="playToP(item)"></el-button>
        </el-tooltip>
        <el-button v-if="!['mp3', 'audio', 'video', 'iframe'].includes(item.type)" icon="el-icon-full-screen" circle @click="setBig(item._id)"></el-button>
        <el-button icon="el-icon-close" circle @click="deleteItem(item._id)"></el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import ItemChild from './itemChild.vue'
export default {
  computed: {
    ...mapGetters(['getMaterial', 'isStudentPaced']),
    ...mapState(['syncUI', 'materialSelect']),
    materialSelectList() {
      return this.getMaterial.filter((v) => this.materialSelect.includes(v._id))
    },
  },
  data() {
    return {
      dialogVisible: true,
    }
  },
  components: {ItemChild},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async playToP(item) {
      // todo plar to project
      console.log('playToP', item, this.syncUI.fullPlayUrl)
      //const fullPlayUrl = item.key ? Fn.hashToUrl(item.key) : `https://www.youtube.com/embed/${item.url}?autoplay=1`
      const fullPlayUrl = item.key ? Fn.hashToUrl(item.key) : `https://www.youtube.com/embed/${item.url}?start=${item.ext?.start??0}`
      if (this.syncUI.fullPlayUrl) return this.$store.dispatch('fullPlayClose')
      this.$store.dispatch('fullPlay', {fullPlayOn: 'projector', fullPlayUrl})
    },
    async deleteItem(id) {
      await this.$store.dispatch('upMaterialSelect', id)
      this.$forceUpdate()
    },
    setBig(id) {
      this.$store.dispatch('setMaterialMaskId', id)
    },
  },
}
</script>

<style scoped>
.mediaFour {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.mediaFourItem {
  width: 50%;
  height: 50%;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #dbd4d4; */
}
.mediaFourItemfull {
  width: 100%;
  height: 100%;
}
.mediaFourItem .btns {
  z-index: 100;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.full {
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 0;
  overflow: hidden;
}
.innermaskmetarial {
  width: 800px;
  height: 600px;
  position: fixed;
  top: 100px;
}
</style>
