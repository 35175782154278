<template>
  <div v-show="fullDoc.url && (isResponseShow || isDashboard || isStudent)" class="flex1 h100 w100 fulldoc" style="position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index: 4001;background: #eee;">
    <el-button class="_close" icon="el-icon-close" @click="close" circle></el-button>
    <iframe v-if="fullDoc.url && Fn.fileType(fullDoc.url) === 'task'" class="w100 h100" :src="getTaskUrl(fullDoc.url)"  frameborder="0" alt=""></iframe>
    <!--
    <embed v-else-if="fullDoc.url && Fn.fileType(fullDoc.url) === 'pdf'" class="h100 w100" :src="Fn.hashToUrl(fullDoc.url)" type="application/pdf">
    -->
    <pdfViewer v-else-if="fullDoc.url && Fn.fileType(fullDoc.url) === 'pdf'" class="h100 w100" :url="Fn.hashToUrl(fullDoc.url)"></pdfViewer>
    <img v-else-if="fullDoc.url && Fn.fileIcon(fullDoc.url) === 'image'" class="w100 h100" style="object-fit: contain;" :src="Fn.hashToUrl(fullDoc.url)" alt="">
    <div v-else class="h100 w100" v-html="html" style="overflow:scroll;"></div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import pdfViewer from './pdfViewer.vue'

export default {
  components: { pdfViewer },
  computed: {
    ...mapState(['fullDoc']),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    isResponseShow() {
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
  },
  watch: {
    'fullDoc.url': {
      async handler(o) {
        if (!o) return
        if (Fn.fileType(o) === 'pdf' || Fn.fileType(o) === 'task') return
        const arrayBuffer = await fetch(Fn.hashToUrl(o)).then((r) => r.arrayBuffer())
        console.log('parse doc to html', o)
        mammoth.convertToHtml({arrayBuffer}, {}).then((result) => {
          this.html = result.value
          console.log(result.messages)
        }).done()
      }, deep: true
    }
  },
  data() {
    return {
      isDashboard: false,
      isStudent: false,
      taskUrl: '',
      html: ''
    }
  },
  beforeDestroy() {
  },
  mounted() {
    this.isDashboard = this.$route.name === 'd'
    this.isStudent = this.$route.name === 's'

    if (Fn.fileType(this.fullDoc.url) === 'task') {
    }
  },
  methods: {
    getTaskUrl(url) {
      const taskId = url.split(':')[2]
      const isDev = /(dev|localhost|127\.0\.0\.1|192\.168\.|10\.0\.)/.test(location.hostname)

      //return isDev ? `http://dev.example.com:9003/v2/unit/view/${taskId}?header=0` : `https://classcipe.com/v2/unit/view/${taskId}?header=0`
      return isDev ? `https://dev.classcipe.com/v2/unit/view/${taskId}?header=0` : `https://classcipe.com/v2/unit/view/${taskId}?header=0`
    },
    close() {
      if (this.isStudent) {
        this.fullDoc.url = null
      } else {
        this.$store.dispatch('upFullDoc', { url: null })
      }
    },
  }
}
</script>
