<template>
  <div class="answer-box flex1">
    <div class="text-answer-container flex1">
      <div class="flex_j" v-if="getQuestion">
        <CommonSwitchTab v-if="['choice', 'comment'].includes(getQuestion.type)" :tabList="[2, 3]" :defaultTab="2" />
        <CommonSwitchTab v-else-if="['media'].includes(getQuestion.type)" :tabList="[2]" :defaultTab="2" />
        <CommonSwitchTab v-else />
        <div class="flex_c" style="min-width: 6rem">Total of {{ total }}</div>
        <el-pagination
          class="flex_c f_1_2"
          :current-page.sync="page"
          :hide-on-single-page="false"
          :page-size="pageSize"
          :total="total"
          layout="prev, pager, next">
        </el-pagination>
      </div>
      <template v-if="getQuestion && !_.isEmpty(selectedAnswerList)">
        <choiceItem
          class="flex1"
          v-if="getQuestion.type === 'choice'"
          v-bind="$attrs"
          :flag_1="flag_1"
          :options="options"
          :selectedAnswerList="selectedAnswerList"
          :shouldShow="shouldShow"
          :classFn="classFn"
          :itemClasses="itemClasses"
          :scrollToItem="scrollToItem"
          :onSelected="onSelected" />
        <textItem
          class="flex1"
          v-else-if="['text', 'number'].includes(getQuestion.type)"
          v-bind="$attrs"
          :flag_1="flag_1"
          :options="options"
          :selectedAnswerList="selectedAnswerList"
          :shouldShow="shouldShow"
          :classFn="classFn"
          :itemClasses="itemClasses"
          :scrollToItem="scrollToItem"
          :onSelected="onSelected" />
        <draw
          class="flex1"
          v-else-if="getQuestion.type === 'draw' && currentAnswerTab !== 3"
          v-bind="$attrs"
          :flag_1="flag_1"
          :selectedAnswerList="selectedAnswerList"
          :shouldShow="shouldShow"
          :classFn="classFn"
          :itemClasses="itemClasses"
          :scrollToItem="scrollToItem"
          :onSelected="onSelected" />
        <AudioItem
          class="flex1"
          v-else-if="getQuestion.type === 'media'"
          v-bind="$attrs"
          :flag_1="flag_1"
          :selectedAnswerList="selectedAnswerList"
          :shouldShow="shouldShow"
          :classFn="classFn"
          :itemClasses="itemClasses"
          :scrollToItem="scrollToItem"
          :onSelected="onSelected" />
        <RemarkItem
          class="flex1"
          style="height: 100%"
          v-if="getQuestion.type === 'comment'"
          v-bind="$attrs"
          :flag_1="flag_1"
          :selectedMarksList="selectedAnswerList"
          :shouldShow="shouldShow"
          :classFn="classFn"
          :itemClasses="itemClasses"
          :scrollToItem="scrollToItem"
          :onSelected="onSelected" />
      </template>
      <loading-view v-else />
    </div>
    <div v-if="flag_1 && isDash" class="clearall" v-show="activeList.length > 0" @click="clearAll">
      <img src="../../assets/picture/clearall.png" width="20px" style="margin-right: 10px" />
      Clear all
    </div>
  </div>
</template>

<style scoped>
.answer-box {
  width: 100%;
  height: 100%;
  position: relative;
}
.clearall {
  width: 160px;
  height: 60px;
  border-radius: 12px;
  background-color: #15c39a;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
}
</style>

<script>
import CommonSwitchTab from './commonSwitchTab.vue'
import LoadingView from './loadingView.vue'
import {mapGetters, mapState, mapActions} from 'vuex'
import textItem from './textItem.vue'
import choiceItem from './choiceItem.vue'
import draw from './draw.vue'
import AudioItem from './AudioItem.vue'
import RemarkItem from './remarkItem.vue'
export default {
  props: {
    currentAnswerCount: {type: Number, default: 0},
    flag_1: {type: Boolean, default: false},
    isDash: {type: Boolean, default: false},
  },
  components: {
    textItem,
    choiceItem,
    draw,
    AudioItem,
    RemarkItem,
    CommonSwitchTab,
    LoadingView,
  },
  data() {
    return {
      page: 0,
      pageSize: 20,
      duplication: false,
      total: 0,
    }
  },
  computed: {
    ...mapGetters(['getQuestion']),
    ...mapState('student', ['currentPageIndex']),
    ...mapGetters('student', ['groupMembers', 'selectedMembers', 'groupMemberIds', 'currentPageAnswerList']),
    ...mapState(['syncUI']),
    currentAnswerTab() {
      return this.$store.getters['teacher/answerTab'](this.currentPageIndex)
    },
    options() {
      return this.flag_1
        ? [
            {value: 1, label: 'sort by time'},
            {value: 2, label: 'sort by name'},
            {value: 3, label: 'sort by response'},
          ]
        : [
            {value: 1, label: 'sort by time'},
            {value: 3, label: 'sort by response'},
          ]
    },
    getMembers() {
      if (!_.isEmpty(this.selectedMembers)) return this.selectedMembers
      if (this.$route.name === 's') return this.groupMembers.map((v) => v._id)
      if (!_.isEmpty(this.groupMemberIds)) return this.groupMemberIds
      return []
    },
    selectedAnswerList() {
      Acan.arrSort(this.currentPageAnswerList, 'updatedAt', 1)
      const rs =
        this.getMembers.length === 0
          ? this.currentPageAnswerList
          : this.currentPageAnswerList.filter((item) => {
              return this.getMembers.indexOf(item.uid) > -1
            })
      this.total = rs.length
      const skip = (this.page - 1) * this.pageSize
      return rs.slice(skip, skip + this.pageSize)
    },
    activeList() {
      return this.currentPageAnswerList.filter((item) => item.star == 1)
    },
  },
  methods: {
    ...mapActions(['setSyncUI']),
    clearAll() {
      this.activeList.map(({_id, star}) => {
        if (star) {
          const data = {_id, star: 0}
          this.$store.dispatch('student/updateAnswerStarOrResponse', data)
        }
      })
    },
    classFn(item) {
      return {
        star_bg: item.star,
        hide_bg: !item.star && item.show_response === 1,
      }
    },
    scrollToItem(id, isRemarkList) {
      if (!id) return
      let target = document.getElementById(id)
      if (!target) return
      let container = document.getElementsByClassName('text-scroll')[0]
      let answserlist = document.getElementsByClassName('text-answer-list')[0]

      if(isRemarkList) {
        container  = document.getElementsByClassName('res-scroll')[0];
        answserlist = document.getElementsByClassName('res-list-1')[0];
      }

      let conRect = container.getBoundingClientRect(),
        ansRect = answserlist.getBoundingClientRect(),
        tarRect = target.getBoundingClientRect()
      container.scrollTop = tarRect.top - ansRect.top
    },
    onSelected(item) {
      if (this.syncUI.currentAnswer == item._id) {
        this.duplication = true
        setTimeout(() => {
          this.setSyncUI({currentAnswer: 0})
        }, 500)
        setTimeout(() => {
          this.duplication = false
          this.setSyncUI({currentAnswer: item._id})
        }, 1000)
      } else {
        this.setSyncUI({currentAnswer: item._id})
      }
    },
    itemClasses(item, currentAnswerTab) {
      return {
        cursor: true,
        'text-item-outer1': currentAnswerTab === 1,
        'text-item-outer5': currentAnswerTab === 2,
        'full-text-area': !this.flag_1,
        'dash-outer': this.getQuestion.type === 'media' && this.flag_1,
        'active-item': this.syncUI.currentAnswer === item._id,
        animate__headShake: this.syncUI.currentAnswer === item._id && this.duplication,
      }
    },
    //返回当前这个item是否应该show出来
    shouldShow(item) {
      if (this.flag_1) return true //如果是dashboard 模式，则一定show
      if (item.show_response == 1) return false //如果要求隐藏，则一定需要隐藏
      if (item.star) return true //如果是星标答案，则需要显示
      for (let i = 0; i < this.selectedAnswerList.length; i++) {
        if (this.selectedAnswerList[i].star) return false //如果不是星标答案，且有其他的星标答案，则需要隐藏
      }
      return true
    },
    resortList(list) {
      let newList = JSON.parse(JSON.stringify(list))
      return newList.reverse()
    },
  },
  mounted() {
    window._std = this
  },
}
</script>
