<template>
  <div class="text-answer-tab">
    <el-tooltip v-for="item in tabList" :content="`${tabName[item]} View`" :key="item" placement="top">
      <button :class="`${className[item]} ${isActive(item)}`" @click="changeNextTab(item)"></button>
    </el-tooltip>
    <el-tooltip v-if="['choice'].includes(getQuestion.type)" content="Edit correct answer" placement="top">
      <button class="button-rule" @click="dialogFormVisible = true"></button>
    </el-tooltip>
    <el-dialog class="change-answer" title="Choose correct answer" :visible.sync="dialogFormVisible" @open="onOpen" @closed="restoreOptions">
      <el-checkbox-group v-if="getQuestion.multi" v-model="checkList" :min="1">
        <el-checkbox v-for="(item, idx) in getQuestion.options" :key="item._id" :label="item._id">{{ optFlags[idx] }}: {{ item.val }}</el-checkbox>
      </el-checkbox-group>
      <el-radio-group v-else v-model="checkList" :min="1">
        <el-radio v-for="(item, idx) in getQuestion.options" :key="item._id" :label="item._id">{{ optFlags[idx] }}: {{ item.val }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onDialogConfirmed">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
export default {
  props: {
    defaultTab: {
      type: Number,
      default: 1,
    },
    tabList: {
      type: Array,
      default: () => [1, 2],
    },
  },
  computed: {
    ...mapGetters(['getQuestion', 'getPageId']),
    ...mapState(['session']),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    currentAnswerTab() {
      return this.$store.getters['teacher/answerTab'](this.currentPageIndex)
    },
  },
  watch: {
    'session.status': {
      handler(o) {
        if (this.isDashboard) {
          this.changeNextTab(this.currentAnswerTab)
        }
      },
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      confirmed: false,
      isDashboard: false,
      checkList: [],
      optFlags: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
      tabName: ['', 'List', 'Grid', 'Overlaid'],
      className: ['', 'button-row', 'button-colum', 'button-statics'],
    }
  },
  mounted() {
    if (!this.currentAnswerTab) {
      this.answerTabBtn([this.currentPageIndex, this.defaultTab])
    }
    this.isDashboard = this.$route.name === 'd'
  },
  methods: {
    ...mapActions('teacher', ['answerTabBtn']),
    isActive(i) {
      const tab = this.tabName[this.currentAnswerTab] ? this.currentAnswerTab : this.defaultTab
      return tab === i ? 'active' : ''
    },
    async changeNextTab(tab) {
      this.answerTabBtn([this.currentPageIndex, tab])
      this.closePicture()
    },
    restoreOptions() {
      this.checkList = []
      if (this.confirmed) {
        this.confirmed = false
      }
    },
    onOpen() {
      this.getQuestion.options.forEach((o) => {
        if (o.on) {
          if (this.getQuestion.multi) {
            this.checkList.push(o._id)
          } else {
            this.checkList = o._id
          }
        }
      })
    },
    async onDialogConfirmed() {
      this.dialogFormVisible = false
      this.confirmed = true

      let allQuestions = JSON.parse(JSON.stringify(this.session.questions))
      let currentPageQuestion = allQuestions.find((q) => q.page == this.getPageId)

      currentPageQuestion.options.forEach((q) => {
        if (this.checkList.indexOf(q._id) > -1) {
          q.on = true
        } else {
          q.on = false
        }
      })

      allQuestions.forEach((q) => {
        if (q.page == this.getPageId) {
          q = currentPageQuestion
        }
      })

      await this.$store.dispatch('upSession', {questions: allQuestions})
    },
    closePicture() {
      if (document.pictureInPictureElement) {
        try {
          document.exitPictureInPicture()
        } catch (e) {}
      }
    },
  },
}
</script>

<style>
.change-answer .el-checkbox-group span.el-checkbox__label {
  display: inline;
}
.change-answer .el-dialog__body {
  padding: 1rem 2rem;
}
</style>
<style scoped>
.text-answer-tab {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.text-answer-tab button {
  width: 32px;
  height: 32px;
  margin-right: 22px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 32px 32px;
  cursor: pointer;
  border: none;
}
.dialog-footer button {
  padding: 0;
  width: 80px;
}
.button-edit i {
  font-size: 1.3rem;
}
.change-answer .el-checkbox,
.el-radio {
  white-space: normal;
  padding-top: 20px;
}
.change-answer .el-checkbox-group,
.el-radio-group {
  text-align: left;
}

.button-rule {
  margin-left: 44%;
  padding: 0;
  background-image: url(../../assets/picture/rule.png);
}
.button-colum {
  background-image: url(../../assets/picture/colum.png);
}
.button-colum.active {
  background-image: url(../../assets/picture/colum-s.png);
}
.button-row {
  background-image: url(../../assets/picture/row.png);
}
.button-row.active {
  background-image: url(../../assets/picture/row-s.png);
}
.button-statics {
  background-image: url(../../assets/picture/static.png);
}
.button-statics.active {
  background-image: url(../../assets/picture/static-s.png);
}
</style>
