<template>
  <div class="remarklist flex1">
    <div class="remarkImage flex_c flex1" style="background-color: #959595;" ref="remarkImageContent">
      <!-- <div class="transformmask">
      </div> -->
      <pptcontent :url="Fn.hashToUrl(getPage.pic || getPage.url)"/>
      <div class="remarkImageBg" ref="remarkImageBg">
        <div v-for="(item) in items" :key="item._id">
          <div
            slot="reference"
            v-if="item.point.pointType !== 'box'" 
            :class="`markitem ${syncUI.currentAnswer == item._id ? 'markitemhover' : ''}`"
            :style="`top:${item.point.top}px;left:${item.point.left}px;`"
            @click="onSelected(item, true)">
            <div class="markitemopacity innermark" :style="`background-color:${item.point.background}; `"/>
          </div>
          <div
            slot="reference"
            v-else-if="item.point.pointType === 'box'"
            :class="`markitembox  ${syncUI.currentAnswer == item._id ? 'markboxhover' : ''}`"
            :style="`top:${item.point.top - 6}px; left:${item.point.left - 6}px;`"
            @click="onSelected(item, true)">
            <div
              class="markitemopacity"
              :style="`width:${item.point.width}px;
              height:${item.point.height}px;
              border: 2px solid ${item.point.background}`"/>
          </div>
        </div>
      </div>
    </div>
    <div class="flex1 res-scroll" style="width: 300px;" v-if="showAnswer">
       <ul class="res-list-1">
          <li class="res-list-item flex1" v-for="item in items" :key="item._id">
            <div
              :tabindex="syncUI.currentAnswer == item._id ? '0' : ''"
              :id="item._id"
              :ref="syncUI.currentAnswer == item._id ? 'activeRef': ''"
              class="res-list-item-content"
              :class="itemClasses(item, 3)" @click="onSelected(item, true)">
              <div class="ans-detail ">
                <CommentItem :item="item"/>
              </div>
            </div>
          </li>
      </ul>
    </div>
  </div>
</template>
<script>
import pptcontent from '@/components/pptcontent.vue'
import audioPlayer from '@/components/common/audioPlayer.vue';
import {mapGetters, mapState, mapActions} from 'vuex'
import CommentItem from '../dash-answer/commentItem.vue';
export default {
  components: { pptcontent, audioPlayer, CommentItem },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemClasses: {
      type: Function,
      default: () => {
        return {}
      },
    },
    scrollToItem: {
      type: Function,
      default: () => {
        return {}
      },
    },
    onSelected: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAnswer: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getPage']),
    //...mapState('teacher', ['remarkFocusId']),
    ...mapState(['syncUI']),
  },
  watch: {
    /*
    syncUI: {
      handler(o) {
        let mark = null
        this.selectedMarksList.forEach((m, idx) => {
          if (o.currentAnswer == m._id) {
            mark = m
          }
        })
        if (mark && mark._id) {
          this.scrollToItem(mark._id, true)
        }
      },
      deep: true,
    },
    remarkFocusId() {
      this.focusIndex()
    }*/
  },
  data() {
    return {
      ratio: 1
    }
  },
  mounted() {
    //console.log(this.items);
    //this.setRemarkFocusId(0)
    this.resizeRemark()
    window.onresize = () => {
      if(this.resizeRemarkDelay) clearTimeout(this.resizeRemarkDelay)
      this.resizeRemarkDelay = setTimeout(this.resizeRemark, 50)
    };
  },
  beforeDestroy() {
    window.onresize = null;
  },
  methods: {
    //...mapActions('teacher', ['setRemarkFocusId']),
    resizeRemark() {
      const w = this.$refs.remarkImageContent.clientWidth
      this.ratio = w / 960
      this.$refs.remarkImageBg.style.transform = `translateY(-50%) translateX(-50%) scale(${this.ratio})`
    },
    /*
    async selectItem(id) {
      this.setRemarkFocusId(id)
    },
    */
    focusIndex() {
      this.$nextTick(() => {
        //this.$refs.activeRef?.[0]?.focus();
        let target = this.$refs.activeRef?.[0];
        if (!target) return
        let container  = document.getElementsByClassName('res-scroll')[0];
        let answserlist = document.getElementsByClassName('res-list-1')[0];

        let conRect = container.getBoundingClientRect(),
          ansRect = answserlist.getBoundingClientRect(),
          tarRect = target.getBoundingClientRect();
        container.scrollTop = tarRect.top - ansRect.top
      });
    },
  }
}
</script>
<style scoped>

</style>
