<template>
  <div class="text-scroll">
    <div class="text-answer-list">
      <template v-for="(item, index) in selectedAnswerList">
        <div :class="`colume${currentAnswerTab === 1 ? '1' : '5'} `" :id="item._id" v-if="shouldShow(item)" @click="onSelected(item)" :key="index">
          <div :class="itemClasses(item, currentAnswerTab)">
            <div class="text-list-item" :class="classFn(item)">
              <div :class="`text_area ${!flag_1 && 'full-text-area'}`">
                <div class="answer-image remark-item-content">
                  <img :src="Fn.hashToUrl(getPage.pic || getPage.url)" style="width: 100%" />
                  <div v-if="item.locked" class="del-answer flex1 flex_c">Deleted response</div>
                  <template v-else-if="item.content">
                    <base64image :url="item.content" />
                    <preview-button :urlBg="Fn.hashToUrl(getPage.pic || getPage.url)" :url="item.content" />
                  </template>
                </div>
                <span class="text_static" v-if="flag_1 && isTextList">
                  {{ index + 1 + ' of ' + selectedAnswerList.length }}
                </span>
              </div>
              <div class="text-footer" v-if="flag_1">
                <StudentResponseOptBar :item="item" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import StudentResponseOptBar from './studentResponseOptBar.vue'
import {mapGetters, mapState, mapActions} from 'vuex'
import previewButton from '../common/previewButton.vue'
import base64image from '../base64image.vue'
export default {
  computed: {
    ...mapGetters(['getPage']),
    isTextList() {
      // 计算是否显示统计 1 of n
      const one = this.selectedAnswerList[0]
      const frr = _.filter(this.selectedAnswerList, {student_user_id: one.student_user_id})
      if (frr.length > 1 && frr.length === this.selectedAnswerList.length) return true
      return false
    },
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    ...mapState(['syncUI']),
    currentAnswerTab() {
      setTimeout(() => {
        this.scrollToItem(this.syncUI.currentAnswer)
      }, 250)
      return this.$store.getters['teacher/answerTab'](this.currentPageIndex)
    },
    isResponseShow() {
      this.scrollToItem(this.syncUI.currentAnswer)
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
  },
  components: {StudentResponseOptBar, base64image, previewButton},
  props: {
    shouldShow: {type: Function, default: () => {}},
    selectedAnswerList: {type: Array, default: []},
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    classFn: {
      type: Function,
      default: () => {
        return {}
      },
    },
    itemClasses: {
      type: Function,
      default: () => {
        return {}
      },
    },
    scrollToItem: {
      type: Function,
      default: () => {
        return {}
      },
    },
    onSelected: {
      type: Function,
      default: () => {
        return {}
      },
    },
    flag_1: {type: Boolean, default: true},
  },
  data() {
    return {
      isStudent: false,
      sortValue: 1,
      duplication: false,
    }
  },
  created() {},
  mounted() {
    this.isStudent = this.$route.name === 's'
  },
  watch: {
    syncUI: {
      handler(o) {
        let answer = null
        this.selectedAnswerList.forEach((a, idx) => {
          if (o.currentAnswer == a._id) {
            answer = a
          }
        })
        if (answer && answer._id) {
          this.scrollToItem(answer._id)
        }
      },
      deep: true,
    },
  },
  methods: {},
}
</script>

<style>
.del-answer {
  position: absolute;
  background: #dfdfdf;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
