<template>
  <div v-if="rect._id" :class="`mitem ${ isSelected ? 'material-focus' : ''}`">
    <div class="full forHover" slot="reference">
      <ItemChild :item="rect"/>
      <div class="flex_c transformmask" v-if="['video'].includes(rect.type)" @click="choose">
        <i class="fa fa-play icon2" style="color:#ddd;"></i>
      </div>
      <div class="flex_c transformmask" v-if="['pdf'].includes(rect.type)" @click="choose">
        <!-- <i class="fa fa-file icon2" style="color:grey;"></i> -->
      </div>
      <div v-else class="transformmask" @click="choose"></div>
      <CommonDeleteButton v-if="rect.source !== 'add-on' && teacher" :deletefn="delFn"/>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import CommonDeleteButton from '../common/commonDeleteButton.vue';
import ItemChild from './itemChild.vue';
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    teacher: {
      type: Boolean,
      default: false
    },
    rect: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  computed: {
    ...mapState(['materialSelect']),
    parentW() {
      let width = 500
      try {
        if(this.parentWidth) {
          if(this.rect.source !== 'add-on' && this.teacher) {
            width = this.parentWidth - 100
          }
          width = this.parentWidth - 60
        }
      } catch(e){}
      return width
    },
    isSelected() {
      return this.materialSelect.includes(this.rect?._id)
    }
  },
  components: {
    ItemChild,
    CommonDeleteButton
  },
  methods: {
    ...mapActions(['delElement']),
    delFn() {
      this.delElement(this.rect._id)
    },
    async choose() {
      await this.$store.dispatch('upMaterialSelect', this.rect._id)
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.teacherppt{
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center
}
.ppt{
  width: 100%;
  height: 100%;
  display: flow-root;
}
.medialist{
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 0;
}
.meidaitem{
  width:300px; height: 200px;
}
.mask{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.full{
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 0;
  overflow: hidden;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 16%);
}
.dragselector{
  /* position: absolute;
  top: -60px; */
  /* left: 50%; */
  /* height: 50px; */
  /* transform: translateX(-50%); */
  /* display: flex;
  align-items: center;
  justify-content: center;
  background-color: #777;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 999; */
  display: flex;
  /* width: 50%; */
  justify-content: center;
}
.cursor{
  cursor: pointer;
}
.mitem{
  width: 180px;
  height: 90px;
  min-height: 90px;
  margin: 15px 2.5px;
  cursor: pointer;
  border: 1px solid #F1F1F1;
}
.material-focus {
  outline: none !important;
  box-shadow: 0px 0px 2px 2px #15c39a;
}
.forHover:hover{
  transform: scale(1.015);
}
.selected-rect{
  border: 2px solid #fb0404;
}
</style>
