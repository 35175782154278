<template>
  <div v-if="url" id="pptContainer" class="ppt pptdisablearea">
    <img class="teacherppt flex1" :src="Fn.hashToUrl(url)"/>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
  }
}
</script>

<style scoped>
.previewcontent .ppt{margin: 0.2rem;}
.teacherppt{
  width: 100%; height: 100%; object-fit: contain;
}
.ppt{
  width: 100%;
  display: flow-root;
  position: relative;
}
.pptdisablearea{
  background-color:#959595
}
</style>