<template>
  <div style="position: relative;">
    <img v-if="student.avatar" :src="urlProxy(student.avatar)" class="student-avatar">
    <div v-else class="def-avatar">{{iconname}}</div>
    <slot/>
  </div>
</template>
<script>
export default {
  props: {
    student: { type: Object, required: true, default: { nickname: '' }}
  },
  computed: {
    iconname() {
      const name = this.student.nickname || this.student.email
      if (!name) return ''
      return name.replace(name[0], name[0].toUpperCase()).substr(0, 2)
    }
  }
}
</script>
<style lang="scss">
.def-avatar,.student-avatar {
  display: flex;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  background-color: #15C39A;
  color: #fff;
  align-items: center;
  justify-content: center;
  margin-right: 0.3rem;
}
</style>
