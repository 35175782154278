<template>
  <div class="remark-item-content-right comment-image">
    <!-- <VideoPlayer
      v-if="item.type === 'video'"
      controlslist="nodownload"
      controls
      :src="item.link"
      width="280"
      height="150"
      preload="auto"
    />
    <audio-player
      v-else-if="item.type === 'audio'"
      :url="item.link"
    />
    <div class="remark-file-right" v-else>
      <p class="file-name-right">{{item.link}}</p>
    </div> -->

    <RemarkImage :item="item" />
  </div>
</template>
<script>
import audioPlayer from '@/components/common/audioPlayer.vue'

import RemarkImage from '../remark/remarkImage.vue'
export default {
  components: {audioPlayer, RemarkImage},
  props: {
    item: {type: Object, default: () => {}},
  },
  methods: {},
}
</script>
<style scoped>
@import url(./dash-right.css);
</style>
