<template>
  <div class="full" v-if="item">
    <div v-if="item.type === 'image'" class="meidaitem teacherppt full">
      <img :src="Fn.hashToUrl(item.key)" class="" />
    </div>
    <div
      v-else-if="item.type === 'video'"
      class="meidaitem teacherppt full"
      @click.stop="$store.commit('localPlay', {fullPlayUrl: Fn.hashToUrl(item.key)})">
      <div v-if="isPreviewBig" class="flex_c w100 h100" style="position: absolute;z-index: 50;" >
        <el-button type="text" class="flex_c icon-button">
          <i class="fa fa-play icon2 icon-play"></i>
        </el-button>
      </div>
      <video controlslist="nodownload" :src="Fn.hashToUrl(item.key)" preload="meta" muted />
    </div>
    <div v-else-if="item.type === 'audio'" class="meidaitem teacherppt full">
      <AudioPlayer :url="Fn.hashToUrl(item.key)" :withMaskButton="true" :id="item._id" />
    </div>
    <div v-else-if="item.type === 'youtube'" class="meidaitem teacherppt full">
      <Youtube :id="item.url" :auto="isPreviewBig" :start="item.ext?.start"/>
    </div>
    <div v-else-if="item.type === 'pdf'" class="meidaitem teacherppt full flex_c" style="line-height:initial;">
      <PDFViewer v-if="isPreviewBig" isMaterial class="w100 h100" :url="Fn.hashToUrl(item.key)" frameborder="0" />
      <span v-else>{{ item.desc || 'Untitled.pdf'}}</span>
    </div>
    <div v-else class="meidaitem teacherppt full flex_c">
      <iframe v-if="isPreviewBig" class="w100 h100" :src="Fn.hashToUrl(item.key)" frameborder="0" />
      <span v-else>{{ item.desc || `Untitled.${item.type}` }}</span>
    </div>
  </div>
</template>
<script>
import AudioPlayer from '../common/audioPlayer.vue'
import PDFViewer from '../pdfViewer.vue'
import Youtube from './youtube.vue'

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isPreviewBig: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PDFViewer,
    AudioPlayer,
    Youtube,
  },
}
</script>
<style scoped>
.full {
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 0;
  text-align: center;
  overflow: hidden;
}
.full img {
  height: 100%;
  object-fit: contain;
  max-width: 100%;
}
</style>
<style scoped lang="scss">
.icon-button {
  padding: 1rem 2rem;
  border-radius: .5rem;
  .icon-play {
    color: #eee;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.5);
    .icon-play {
      color: #fff;
      transform: scale(1.2)
    }
  }
}

</style>
