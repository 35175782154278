/* eslint-disable space-infix-ops */

export const getUrlParam = (queryKey: string, url = location.href): string => {
  const temp1 = url.split('?');
  const pram = temp1[1];
  if (!pram) return ''
  const keyValue = pram.split('&');
  const obj: any = {};
  for (let i = 0; i < keyValue.length; i++) {
    const item = keyValue[i].split('=');
    const key = item[0];
    const value = item[1];
    obj[key] = value;
  }
  return obj[queryKey]
}

export const getTimeValue = (value: number) => {
  const date = new Date(value)
  return {
    year: date.getFullYear(),
    month: `0${date.getMonth() + 1}`.substr(-2),
    date: `0${date.getDate()}`.substr(-2),
    hours: `0${date.getHours()}`.substr(-2),
    minutes: `0${date.getMinutes()}`.substr(-2),
    seconds: `0${date.getSeconds()}`.substr(-2),
  }
}

export const getAnswerTimeStr = (time: number) => {
  if(!time) return ''
  const {
    year,
    month,
    date,
    hours,
    minutes
  } = getTimeValue(time)
  return `${year}-${month}-${date} ${hours}:${minutes}`
}

export const getJSONValue = (res: any, clone = false) => {
  if(!res) return {}
  if(Object.prototype.toString.call(res) === "[object Object]") return res
  let data = {}
  try {
    data = JSON.parse(res)
  } catch(e) {}
  return clone ? JSON.parse(JSON.stringify(data)) : data
}

export const getHashCode = (hashString: any) => {
  if(typeof hashString === 'object') {
    hashString = JSON.stringify(hashString)
  }
  let result = hashString.split("").reduce((a: number, b: string) => { a = ((a << 5) - a) + b.charCodeAt(0); return a&a }, 0);
  // console.log('hashKey', result)
  return result.toString()
}
