<template>
  <div class="remark-item-content-right">
    <VideoPlayer v-if="Fn.fileType(item.content) === 'video'" :src="item.content"
      controlslist="nodownload" :controls="true" width="280" height="150" style="height: 150px"/>
    <audio-player v-else-if="Fn.fileType(item.content) === 'audio'" :url="item.content" />
    <div class="remark-img" v-else-if="Fn.fileType(item.content) === 'task'" @click="$store.dispatch('upFullDoc', { url: item.content })">
      <base64image v-if="item.content && item.content.includes(':')" :url="item.content.split(':')[0]"/>
    </div>
    <div class="remark-img" v-else-if="Fn.fileType(item.content) === 'image'">
      <base64image :url="item.content" :showPreview="true" :noSync="noSync"/>
    </div>
    <div class="remark-file-right" v-else @click="$store.dispatch('upFullDoc', { url: item.content })">
      <div class="file-icon" :class="Fn.fileIcon(item.content)"></div>
      <div class="flex1">
        <p class="file-name-right">{{fileName}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import audioPlayer from '@/components/common/audioPlayer.vue';
import base64image from '@/components/base64image.vue';
export default {
  components: { audioPlayer, base64image },
  props: {
    noSync: { type: Boolean, default: false },
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    fileName() {
      if (!this.item.content?.split) return ''
      return this.item.content.split(':').pop()
    }
  },
  methods: {
  }
}
</script>
<style scoped>
@import url(./dash-right.css);
</style>
