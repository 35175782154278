<template>
  <el-popconfirm @confirm="deletefn" confirm-button-text="OK" cancel-button-text="No,Thanks" icon="el-icon-info" title="Are you sure you want to delete this?">
    <div slot="reference" class="delete-button"></div>
  </el-popconfirm>
</template>
<script>
export default {
  props: {
    deletefn: {
      type: Function,
      default: () => null,
    },
  },
}
</script>
<style scoped>
.delete-button {
  width: 30px;
  height: 30px;
  border-radius: 17px;
  background-image: url(../../assets/picture/mdel.png);
  background-size: 30px 30px;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 9999;
}
.delete-button:hover {
  background-color: tomato;
}
</style>
