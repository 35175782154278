<template>
  <div
    class="pdf-main"
    ref="pdfRef"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.4)">
    <div class="actions flex_c">
      <div class="toggle" v-if="!isMaterialMin">
        <img class="" v-if="!syncUI.pdfFull" @click="setSyncUI({pdfFull: true})" width="50" height="50" src="../assets/icon/screen_all.png" alt="" />
        <img class="" v-else @click="setSyncUI({pdfFull: false})" width="50" height="50" src="../assets/icon/minimize.png" alt="" />
      </div>
    </div>
    <div ref="wrapperRef" class="canvas__wrapper" @scroll="updateScrollTop">
      <canvas ref="canvasRef" class="canvas__container"></canvas>
    </div>
    <div class="nav flex_c">
      <el-button
        @click="navPage(initialState.currentPage - 1)"
        size="small"
        :disabled="initialState.currentPage == 1"
        icon="el-icon-arrow-left"
        circle></el-button>
      <div class="page-info">
        Page <span>{{ initialState.currentPage }}</span> of <span>{{ initialState?.pdfDoc?.numPages ?? 'Loading...' }}</span>
      </div>
      <el-button
        @click="navPage(initialState.currentPage + 1)"
        size="small"
        :disabled="initialState.currentPage == initialState?.pdfDoc?.numPages"
        icon="el-icon-arrow-right"
        circle></el-button>
    </div>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
const { pdfjsLib } = globalThis
pdfjsLib.GlobalWorkerOptions.workerSrc = '/plugin/pdf.worker.4.0.242.mjs'

export default {
  computed: {
    ...mapState(['syncUI']),
    isMaterialMin() {
      return !this.$store.state.materialMaskId && this.isMaterial
    },
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    isMaterial: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'syncUI.pdfFull': {
      handler(o) {
        this.renderPage()
      },
    },
    'syncUI.pdfPage': {
      handler(o) {
        if (!this.isMaterialMin) {
          this.goToPage(o)
        }
      },
    },
    'syncUI.pdfScrollTop': {
      handler(o) {
        if (!this.isMaterialMin) {
          this.$refs.wrapperRef.scrollTop = o
        }
      },
    },
  },
  data() {
    return {
      processi: 0,
      loaded: false,
      realUrl: null,
      interval: null,
      scrollTop: 0,
      lastHeight: 0,
      loading: false,
      initialState: {
        pdfDoc: null,
        currentPage: 1,
        height: 0,
        width: 0,
        zoom: 1,
      },
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }

    this.setSyncUI({pdfFull: false, pdfPage: 1, pdfScrollTop: 0})
  },
  mounted() {
    this.interval = setInterval(this.process, 100)
    if (this.syncUI.pdfPage) {
      this.initialState.currentPage = this.syncUI.pdfPage
    }
    this.loading = true
  },
  methods: {
    ...mapActions(['setSyncUI']),
    loadPdf(pdf) {
      const that = this
      this.realUrl = this.url
      this.loaded = true
      pdfjsLib
        .getDocument(pdf)
        .promise.then((data) => {
          this.initialState.pdfDoc = data
          this.renderPage()
        })
        .catch((err) => {
          alert(err.message)
        })
    },
    goToPage(pageNum) {
      this.initialState.currentPage = pageNum
      this.$refs.wrapperRef.scrollTop = 0
      this.renderPage()
    },
    navPage(pageNum) {
      if (this.isMaterialMin || this.isMaterial) {
        this.goToPage(pageNum)
      } else {
        this.setSyncUI({pdfPage: pageNum})
      }
    },
    renderPage() {
      this.initialState.pdfDoc.getPage(this.initialState.currentPage).then((page) => {
        const canvas = this.$refs.canvasRef

        let viewport = page.getViewport({
          scale: 1,
        })

        if (!this.$store.state.materialMaskId && this.isMaterial) {
          let height = this.$refs.pdfRef.clientHeight
          this.lastHeight = height
          this.initialState.zoom = height / viewport.height
        } else if (this.syncUI.pdfFull) {
          this.initialState.zoom = window.innerWidth / viewport.width
        } else {
          this.setSyncUI({pdfScrollTop: 0})
          this.initialState.zoom = window.innerHeight / viewport.height
        }

        viewport = page.getViewport({
          scale: this.initialState.zoom,
        })

        const pixelRatio = 2
        canvas.height = viewport.height * pixelRatio
        canvas.width = viewport.width * pixelRatio

        canvas.style.height = viewport.height + 'px'
        canvas.style.width = viewport.width + 'px'

        const ctx = canvas.getContext('2d')
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)

        const renderCtx = {
          canvasContext: ctx,
          viewport: viewport,
        }

        page.render(renderCtx)
        this.loading = false
      })
    },
    updateScrollTop(event) {
      this.processi = 0
      this.scrollTop = this.$refs.wrapperRef.scrollTop
    },
    process() {
      if (this.loaded && this.isMaterialMin && this.lastHeight && this.lastHeight !== this.$refs.pdfRef.clientHeight) {
        this.renderPage()
      }

      if (!this.loaded || this.realUrl !== this.url) {
        this.loadPdf(this.url)
      }

      let needSync = false

      if (this.processi > 8) {
        if (this.syncUI.pdfScrollTop != this.scrollTop) {
          needSync = true
        }
        this.processi = 0
      }
      if (needSync) {
        this.setSyncUI({pdfScrollTop: this.scrollTop})
      } else {
        this.processi++
      }
    },
  },
}
</script>
<style scoped lang="sass">
.actions
  position: absolute
  top: 10px
  left: 10px
  text-shadow: 0px 1px 0 #bcbcbc, 0px 1px 0 #9c9c9c
  .toggle
    padding-right: 1rem
  img
    vertical-align: middle
    cursor: pointer
    &:hover
      opacity: 0.8

.nav
  position: absolute
  bottom: 10px
  left: 0px
  width: 100%
  .page-info
    padding: 0 1rem

.canvas__wrapper
  width: 100%
  height: 100%
  overflow-y: auto
  overflow-x: auto
  scroll-behavior: smooth

.canvas__container
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  margin: 0px auto
</style>
