<template>
  <el-dialog :visible.sync="previewVisable" width="70%" @close="setCurrentPreviewUrl" :destroy-on-close="true">
    <div style="padding: 0 0.5rem; background: #fff">
      <div class="previewcontent" style="position: relative">
        <div style="position: relative; text-align: center">
          <embed v-if="currentPreviewData.type === 'pdf'" style="width: 100%" height="550" :src="currentPreviewData.url" />
          <template v-else-if="currentPreviewData.urlBg">
            <img :src="currentPreviewData.urlBg" style="width: 100%" />
            <Base64image :url="Fn.hashToUrl(currentPreviewData.url)" />
          </template>
          <template v-else-if="currentPreviewData.type === 'image'">
            <img :src="Fn.hashToUrl(currentPreviewData.url)" class="current-preview" />
          </template>
          <RemarkList v-else-if="currentPreviewData.type === 'comment'" :items="toArrayComment(currentPreviewData.url)" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {mapState, mapActions} from 'vuex'
import Base64image from '../base64image.vue'
import RemarkList from '../teacher/remark/remarkList.vue'
export default {
  props: {
    isShowResponse: {
      type: Boolean,
      default: false,
    },
  },
  components: {Base64image, RemarkList},
  computed: {
    ...mapState({
      currentPreviewData: (state) => state.teacher.currentPreviewData,
    }),
  },
  watch: {
    currentPreviewData() {
      if (this.isProject && !this.isShowResponse) {
        this.previewVisable = false
      } else {
        this.previewVisable = !!this.currentPreviewData.url
      }
    },
  },
  data() {
    return {
      previewVisable: false,
      isProject: location.href.indexOf('/t/') > -1,
    }
  },
  methods: {
    ...mapActions('teacher', ['setCurrentPreviewData']),
    setCurrentPreviewUrl() {
      if (event?.type === 'message') return
      this.previewVisable = false
      this.setCurrentPreviewData({type: '', url: ''})
    },
    toArrayComment(comment) {
      if (Array.isArray(comment)) return comment
      return [comment]
    },
  },
}
</script>
<style scoped>
.previewcontent {
  width: 100%;
  height: 100%;
  position: relative;
}
.current-preview {
  max-height: 60vh;
  max-width: 100%;
}
</style>
