<template>
  <div class="remark-item-content-right">
    <el-button
      style="position: absolute; top: 0.75rem; right: 0.5rem; z-index: 10"
      v-if="['video', 'audio'].includes(Fn.fileType(item.content)) && $route.name === 'd'"
      icon="el-icon-monitor"
      :disabled="isStudentPaced"
      circle
      @click="$store.dispatch('fullPlay', {fullPlayUrl: Fn.hashToUrl(item.content), fullPlayOn: 'projector'})" />
    <!--
    <el-tooltip 
      style="position: absolute; top: 0.5rem; right: 0.5rem; z-index: 10"
      v-if="['file'].includes(Fn.fileType(item.content)) && $route.name === 'd'" 
      class="item" 
      effect="dark" 
      content="Draw it" 
      placement="top-start">
      <i @click="setSyncUI({ drawUrl: Fn.hashToUrl(getPage.pic || getPage.url), drawShow: false, drawText: item._id})" class="answer-footer-button draw"></i>
    </el-tooltip>
      -->
    <el-button
      style="position: absolute; top: 0.5rem; right: 0.5rem; z-index: 10"
      v-if="['file'].includes(Fn.fileType(item.content)) && $route.name === 'd'"
      :disabled="isStudentPaced"
      circle
      @click="setSyncUI({ drawUrl: Fn.hashToUrl(getPage.pic || getPage.url), drawShow: false, drawText: item._id})" >
      <i class="draw-it"></i>
    </el-button>
    <videoPlayer v-if="Fn.fileType(item.content) === 'video'" controlslist="nodownload" :src="item.content" width="280" height="150" :controls="true" />
    <div v-else-if="Fn.fileType(item.content) === 'audio'" style="width:100%;padding-right:50px;">
      <audioPlayer :url="item.content" />
    </div>
    <div class="remark-file-right" v-else>
      <p class="file-name-right" :class="{'deleted-response': !item.content}" style="padding-right:2.5rem;">{{ item.content || 'Deleted Response'}}</p>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import audioPlayer from '@/components/common/audioPlayer.vue'
import videoPlayer from '@/components/common/videoPlayer.vue'

export default {
  components: {audioPlayer, videoPlayer},
  props: {
    item: {type: Object, default: () => {}},
  },
  methods: {
    ...mapActions(['setSyncUI']),
  },
  computed: {
    ...mapGetters(['isStudentPaced', 'getPage']),
  },
}
</script>
<style scoped>
.draw-it{
  background-image: url(../../../assets/picture/draw.png);
  cursor: pointer;
  width: 15px;
  height: 15px;
  background-position: 0 0;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  display: block;
}
.el-icon-edit {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
}
@import url(./dash-right.css);
</style>
