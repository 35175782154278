<template>
  <div class="transformmask" style="z-index: 4000; backgroud: rgba(0,0,0,0.5)" v-if="bigItem">
    <div class="innermaskmetarial" >
      <ItemChild :item="bigItem" :isPreviewBig="true"/>
      <div class="btns">
        <el-button icon="el-icon-close" circle @click="setBig"></el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import ItemChild from './itemChild.vue';
export default {
  computed: {
    ...mapGetters(['getMaterial']),
    ...mapState(['materialMaskId']),
    bigItem() {
      if(!this.materialMaskId) return null
      return this.getMaterial.find(item => item._id == this.materialMaskId)
    },
  },
  components: {
    ItemChild
  },
  methods: {
    setBig() {
      this.$store.dispatch('setMaterialMaskId', null)
    }
  }
}
</script>

<style scoped>
.innermaskmetarial{
  width: 100%;height: 100%;
  position: fixed;
  top: 0;left: 0;right: 0;bottom: 0;
  background-color: #fff;
  z-index: 4001;
}
.btns{position:absolute;top:1rem;right:1rem;}
</style>