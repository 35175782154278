<template>
<div v-if="item" class="tfleft-answer tfshadow">
  <div v-if="item.type === 'comment'">
    <div class="tfanswer-media flex-center">
      <div class="tfinner-media" >
        <RemarkImage :item="item" :noSync="true"/>
      </div>
    </div>
  </div>
  <div v-else-if="item.type === 'draw'">
    <div class="tfanswer-media flex-center">
      <div class="tfinner-media" >
        <img :src="Fn.hashToUrl(getPage.pic || getPage.url)" style="width:100%;"/>
        <base64image :url="item.content" />
        <previewButton :urlBg="Fn.hashToUrl(getPage.pic || getPage.url)" :url="item.content" :noSync="true"/>
      </div>
    </div>
  </div>
  <div v-else-if="['choice', 'text'].includes(item.type)" class="padding:0.5rem;">
    <div class="tfanswer-text" :class="{'deleted-response': !item.content}">{{item.content || 'Deleted Response'}}</div>
  </div>
  <div v-else-if="item.type === 'media'">
    <dash-right-media-item :item='item' :noSync="true"/>
  </div>
  <div class="tfanswerdetail" v-else-if="checkType(item.title) === 'string'">
    <div class="tfanswer-media flex-center" v-if="item.title.indexOf('data:image/') > -1">
      <div class="tfinner-media" >
        <base64image :url="Fn.hashToUrl(getPage.pic || getPage.url)">
          <base64image :url="item.title" :noSync="true"/>
        </base64image>
      </div>
    </div>
    <div class="tfanswer-media flex-center" v-else-if="item.title.indexOf('.mp3') > -1">
      <div class="tfinner-media" >
        <audio-player :url="item.title"/>
      </div>
    </div>
    <div class="tfanswer-media flex-center"  v-else-if="item.title.indexOf('.webm') > -1">
      <div class="tfinner-media">
        <VideoPlayer :controls="false" :src="item.title" style="width:100%;" />
      </div>
    </div>
    <div class="tfanswer-text" v-else-if="item.title.indexOf('[') > -1">
      <div v-for="(text, index) in getAnswer(item.title)" :key="index">{{ text }}</div>
    </div>
    <div class="tfanswer-text" v-else>
      {{item.title}}
    </div>
  </div>
  <!-- <studentInfo v-if="item.uid" :data="{ ...item, last: item.updatedAt }"/> -->
  <slot />
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import studentInfo from '../../common/studentInfo.vue'
import { getTimeValue } from '@/utils/help';
import RemarkImage from '../remark/remarkImage.vue';
import base64image from "../../base64image.vue";
import previewButton from '../../common/previewButton.vue'
import AudioPlayer from '@/components/common/audioPlayer.vue';
import DashRightMediaItem from '../dash-answer/dash-right-media-item.vue';
export default {
  components: { studentInfo, base64image, RemarkImage, previewButton, AudioPlayer, DashRightMediaItem},
  props: {
    item: { type: Object, default: () => {}},
    userData: { type: Object, default: () => {}}
  },
  computed: {
    ...mapGetters(['getPage']),
    ...mapState({
      allRemarks: state => state.remark.allRemarks,
      currentPageIndex: state => state.student.currentPageIndex
    }),
    itemInfo() {
      if (this.pageType === 'comment') return this.allRemarks.find(o => o.id === this.item.id)
      return false
    },
    pageType() {
      return this.getPage.type
    }
  },
  mounted() {
  },
  created() {},
  methods: {
    getTimeText(time) {
      if(time > 0) {
        const {
          year,
          month,
          date,
          minutes,
          hours,
          seconds
        } = getTimeValue(time*1000)
        return `${date}/${month}/${year} ${hours}:${minutes}:${seconds}`
      }
      return ''
    },
    checkType(title) {
      return (typeof (title)).toLocaleLowerCase()
    }
  }
};
</script>
<style scoped>
*{box-sizing: border-box;}

</style>
