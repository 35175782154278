<template>
<el-dialog :title="'Feedback for ' + nickname(commentData.uid)" :visible.sync="modalVisiable" width="70%" :before-close="closeModal" :destroy-on-close="true">
  <div class="tfmbox" v-if="commentData.page">
    <div class="tfleft" v-if="addmore || !commentList.length">
      <TeacherRes :item="commentData" />
      <!--
      <div v-if="(commentList && commentList.length > 0) && !addmore" class="tffooter-button-more" @click="addmoreFeed">
        <p class="boldText tfmore-feed">Add More Feedback</p>
        <img src="../../assets/picture/send.png" class="tfmore-icon"/>
      </div>
      -->
      <div class="tfedit-area tfshadow">
        <div class="tftextarea-box">
        <!--
          <textarea class="tftextarea" v-model="commentValue" placeholder="" @focus="focus" @blur="blur"/>
          <div class="tftext-placeholder" v-show="showPlaceholder">Leave feedback for this response……</div>
          -->
          <keyboard class="tftextarea" focus="true" rows=3 type="textarea" v-model="commentValue" placeholder="Leave feedback for this response..." @focus="focus" @blur="blur"/>
        </div>
        <div class="tffooter-button" @click="sendMessage" v-loading="sendLoading">
          <div :class="`boldText tfsend-button ${!showPlaceholder && 'tfactive'}`">Send Feedback</div>
        </div>
      </div>
    </div>
    <div v-if="(commentList && commentList.length > 0) && !addmore" class="tffooter-button-more" @click="addmoreFeed">
      <p class="boldText tfmore-feed">Add More Feedback</p>
      <img src="../../assets/picture/send.png" class="tfmore-icon"/>
    </div>
    <div class="tfright" v-if="commentList && commentList.length > 0">
      <TeacherRes v-for="(o, index) in commentList" :item="{ type: o.otype, content: o.otext, updatedAt: o.updatedAt, point: o.point }" :key="index">
        <div style="margin:1rem;"></div>
        <studentInfo :data="{ uid: o.from, nickname: o.sender, last: o.updatedAt }"/>
        <div class="tffeed-item feed-media" v-if="o.commentType === 'video'">
          <VideoPlayer :controls="false" :src="o.review" style="width:100%;" />
        </div>
        <div class="tffeed-item feed-media feed-media-audio" v-else-if="o.commentType === 'audio'">
          <AudioPlayer :url="o.review"/>
        </div>
        <div v-else class="tffeed-item feed-text">
          {{ o.review }}
        </div>
      </TeacherRes>
      <div class="tftransformmask"></div>
    </div>
    <div v-else class="tfempty">
      <img src="../../assets/picture/empty-feed.png" class="tfempty-img"/>
      <p class="tftitle">No Feedback Yet</p>
      <p class="tfempty-tip">Go to add your first reply</p>
    </div>
  </div>
</el-dialog>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { getTimeValue } from "@/utils/help";
import base64image from "../base64image.vue";
import RecordVideo from "../common/recordVideo.vue";
import keyboard from '@/components/common/keyboard.vue'
import TeacherRes from './comment/TeacherRes.vue';
import AudioPlayer from '../common/audioPlayer.vue';
import studentInfo from '../common/studentInfo.vue'
// import
export default {
  components: { keyboard, base64image, RecordVideo, TeacherRes, AudioPlayer, studentInfo },
  data() {
    return {
      commentValue: "",
      sendLoading: false,
      isRecording: false,
      endRecording: false,
      textFocus: false,
      addmore: false
    };
  },
  computed: {
    ...mapGetters('teacher', ['memberNames']),
    ...mapGetters('student', ['groupMemberNames']),
    showPlaceholder() {
      return !this.commentValue
    },
    ...mapState('teacher', ['commentData']),
    commentList() {
      const { _id, uid, page } = this.commentData
      if (!_id) return []
      const filterData = this.$store.state.teacher.feedBackList.filter(item => {
        return page === item.page && uid === item.to && _id === item.rid
      })
      const mapData = filterData.map((item) => {
        if (!item.teacherName) item.teacherName = currentNickname
        return {...item, title: item.content}
      })
      return mapData
    },
    modalVisiable() {
      return this.commentData._id ? true : false
    }
  },
  mounted() {
    window._this2=this
    //console.log(this.commentList, 'commentModal')
    EventBus.$on('hideCommentModal', this.closeModal)
  },
  methods: {
    ...mapMutations('teacher', ['setCommentData']),
    ...mapActions("teacher", ["addFeedBack"]),
    nickname(uid) {
      if (this.$route.name === 's') return this.groupMemberNames[uid] || ''
      return this.memberNames[uid] || ''
    },
    focus() {
      this.textFocus = true
    },
    blur() {
      this.textFocus = false
    },
    closeModal() {
      this.addmore = false
      this.setCommentData({})
      this.$forceUpdate()
    },
    async sendMessage() {
      if (!this.commentValue) {
        this.$message.warning("Please input your comment");
        return;
      }
      if (this.sendLoading) return
      this.sendLoading = true
      await this.sendComment(this.commentValue, "text");
      this.commentValue = "";
      this.sendLoading = false
    },
    async sendComment(value, commentType = "text") {
      const { sid, page, _id: rid, uid: to, nickname, content, type: otype, point } = this.commentData;
      let otext = content
      const post = { rid, sid, page, to, otype, otext, receiver: nickname, from: currentUserId, sender: currentNickname, review: value, point }

      console.log(this.commentData, post, 'add feedback')
      const rs = await App.service('comments').create(post).catch((err) => {
        throw err
        return
      })
      if (rs) this.addFeedBack(rs)
      // this.commentList.unshift({ ...this.commentData, ...data })
      this.addmore = false
    },
    addmoreFeed() {
      this.addmore = true
    },
    sendVideoOrAudio(url, type = "text") {
      this.sendComment(url, type);
    }
  }
};
</script>
<style scoped>
@import url(../../assets/css/teacherfeed.css);
</style>
