<template>
  <div class="record-area">
    <div v-if="!isAniInFixed" class="float-line audio-line w100 flex_c animation-line">
      <div class="audio-play"></div>
      <div class="audio-play"></div>
      <div class="audio-play"></div>
      <div class="audio-play"></div>
      <div class="audio-play"></div>
    </div>
    <div v-show="!endRecording">
      <div v-if="!audioUrl">
        <!--
        <div class="record-footer">
          <i class="done scale-animation" @click="done"></i>
          <p v-if="!endRecording" class="record-time">{{getTime(timeValue)}} / 02:00</p>
        </div>
        -->
      </div>
    </div>
    <div v-show="!endRecording">
      <div class="fixed-area" v-if="!audioUrl">
        <audio id="record-audio" width="1" height="1" src="opacity: 0"/>
        <div v-if="isAniInFixed" class="audio-line w100 flex_c animation-line">
          <div class="audio-play"></div>
          <div class="audio-play"></div>
          <div class="audio-play"></div>
          <div class="audio-play"></div>
          <div class="audio-play"></div>
        </div>
        <div class="record-footer">
          <el-button v-if="!endRecording" class="record-button" type="primary" @click="done">DONE</el-button>
          <!--
          <i class="done scale-animation" @click="done"></i>
          -->
          <p v-if="!endRecording" class="record-time">{{getTime(timeValue)}} / 02:00</p>
        </div>
      </div>
    </div>
    <common-progress :progress="progress" :cancel="cancelUp"/>
  </div>
</template>
<script>
import {startRecordAudio, saveRecordAudio, endRecordAudio, cancelUpAudio} from '@/utils/audio'
import { mapGetters } from "vuex"
import audioPlayer from './audioPlayer.vue'
import CommonProgress from './commonProgress.vue'
export default {
  components: { audioPlayer, CommonProgress },
  computed: {
    ...mapGetters(['getQuestion']),
  },
  props: {
    onSend: {
      type: Function,
      default: () => null
    },
    cancel: {
      type: Function,
      default: () => null
    },
    onRecordDone: {
      type: Function,
      default: () => null
    },
    autoDone: {
      type: Boolean,
      default: false // 自动完成提交，不要二次确认
    },
    isAniInFixed: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      endRecording: false,
      timeValue: 0,
      color: '#999',
      maxTime: 120, // 秒
      audioUrl: '',
      page:'',
      type:'',
      visible: false,
      progress: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startRecord()
    })
  },
  beforeDestroy() {
    this.clearCount()
    if(!this.endRecording) {
      endRecordAudio()
    }
    cancelUpAudio()
    // this.$message(`The audio is canceled`)
  },
  methods: {
    count() {
      this.timer = setInterval(() => {
        if(this.timeValue >= this.maxTime) {
          this.done()
        } else {
          this.timeValue ++
          this.color = this.timeValue % 2 === 1 ? '#999' : 'red'
        }
        
      }, 1000)
    },
    clearCount() {
      if(this.timer) clearInterval(this.timer)
      this.color = '#999'
    },
    getTime(value) {
      // if(value < 60) {
      //   return `00:00:${value}`
      // }
      const hours = Math.floor(value / 3600)
      const minutes = Math.floor((value - hours * 3600) / 60)
      const seconds = value - hours * 3600 - minutes * 60
      return `${('0' + minutes).substr(-2)}:${('0' + seconds).substr(-2)}`
    },
    async done() {
      if(this.endRecording) return
      this.onRecordDone()
      this.endRecording = true
      const rs = await saveRecordAudio()
      console.log(rs, 'save audio');
      const str = [rs._id, rs.ext].join(':')
      if(rs._id) {
        if(this.autoDone) {
          //this.onSend(str)
          this.sendRecord(str)
        } else {
          // 发送url信息
          this.audioUrl = str
          this.sendRecord()
        }
      }
      this.clearCount()
    },
    onProgressUpLoad(progress) {
      this.progress = progress
    },
    startRecord() {
      const {page, type} = this.getQuestion
      this.page = page
      this.type = type
      this.timeValue = 0
      startRecordAudio(document.getElementById("record-audio"), () => {
        this.endRecording = false
        this.count()
      }, () => {
        this.cancelUp()
      })
    },
    sendRecord(str){
       //this.onSend(this.audioUrl, 'audio')
       this.onSend(str || this.audioUrl, this.page, this.type)
    },
    cancelUp() {
      cancelUpAudio()
      this.onProgressUpLoad(0)
      this.cancel()
    }
  }

}
</script>

<style scoped>
.record-area{
  height: 60px;
  width: 100%;
  position: relative;
}
.tips-area{
  width: 5px;
  height: 5px;
  background: transparent;
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
}
.record-button-group{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.record-tip-text{
  font-size: 14px;
  line-height: 24px;
  color: #474747;
}
.cancel{
  width: 60px;
  height: 24px;
  background: #D0D2DF;
  opacity: 1;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
}
.cancel:hover{
  background-color: rgba(21, 195, 154, 1);
}
.cancel.primary{
  /* background-color: rgba(21, 195, 154, 1); */
  margin-left: 12px;
}
.fixed-area{
  height: 60px;
  width: 280px;
  position: fixed;
  top: 5px ;
  right: 35px;
  z-index: 999999;
}
.record-footer{
  height: 58px;
  box-sizing: border-box;
  background-color: rgba(43,51,63,0.7);
  width: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.done{
  border-radius: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: red;
  margin-right: 20px;
}
.record-time{
  font-size: 10px;
  margin-left: 20px;
  color: #fff;
}

</style>
