var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"remarkmodal",staticClass:"remark-modal"},[_c('div',{ref:"remarkmaskinner",staticClass:"remark-mask-inner"},[_c('div',{staticClass:"pointer-area",attrs:{"onselectstart":"return false"},on:{"mousedown":function($event){return _vm.startMove($event)},"mousemove":function($event){return _vm.mouseMoving($event)},"mouseup":function($event){return _vm.mouseEnd($event)},"mouseleave":function($event){return _vm.mouseLeave($event)},"drag":function($event){return _vm.pauseEvent($event)}}},[_vm._l(([..._vm.currentMark, ..._vm.myPageAnswerList]),function(item,index){return [_c('el-popover',{key:item._id == -1 ? index : item._id,attrs:{"placement":"top","trigger":"hover","append-to-body":true,"visible-arrow":false,"disabled":_vm.isMovingPos,"popper-class":"transparent-content","popper-options":{
            boundariesElement: 'body',
            gpuAcceleration: true,
            positionFixed: true,
            preventOverflow: true,
          }}},[_c('ul',{staticClass:"pop-color-list"},_vm._l((_vm.hoverColors),function(color){return _c('li',{key:color,staticClass:"pop-color-item",on:{"click":function($event){$event.stopPropagation();return _vm.pickColor(item, index, color)}}},[_c('div',{staticClass:"pop-color-item-box",style:(`background-color:${color}`)})])}),0),(item.point.pointType !== 'box')?_c('div',{class:`markitem ${_vm.currentRemarkIndex === index || !item._id ? 'markitemhover' : ''}`,style:(`top:${item._id && item._id == _vm.moveItem._id ? _vm.moveItem.pos?.top : item.point?.top}px;left:${item._id && item._id == _vm.moveItem._id ? _vm.moveItem.pos?.left: item.point?.left}px;`),attrs:{"slot":"reference"},on:{"mousedown":function($event){$event.stopPropagation();return _vm.selectMark(item, index)},"mouseup":function($event){$event.stopPropagation();return _vm.mouseEnd($event)},"mouseleave":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();}},slot:"reference"},[_c('div',{staticClass:"markitemopacity innermark",style:(`background-color:${item.point.background}; `)})]):(item.point.pointType === 'box')?_c('div',{class:`markitembox ${_vm.currentRemarkIndex === index || !item._id ? 'markboxhover' : ''}`,style:(`top:${item.point?.top - 6}px; left:${item.point?.left - 6}px;`),attrs:{"slot":"reference"},on:{"mousedown":function($event){$event.stopPropagation();return _vm.selectMark(item, index)},"click":function($event){$event.stopPropagation();return _vm.mouseEnd($event)},"mouseup":function($event){$event.stopPropagation();},"mouseleave":function($event){$event.stopPropagation();}},slot:"reference"},[_c('div',{staticClass:"markitemopacity",style:(`width:${item.point.width}px;
                height:${item.point.height}px;
                border: 2px solid ${item.point.background}`)})]):_vm._e()])]}),(_vm.markType === 2 && _vm.isBoxing)?_c('div',{staticClass:"dragbg",on:{"click":function($event){$event.stopPropagation();}}}):_vm._e(),(_vm.markType === 2 && _vm.isBoxing)?_c('div',{style:(`width:${Math.abs(_vm.nextPosition.offsetX - _vm.currentPosition.offsetX)}px;
        height:${Math.abs(_vm.nextPosition.offsetY - _vm.currentPosition.offsetY)}px;
        position: absolute;
        left: ${Math.min(_vm.currentPosition.offsetX, _vm.nextPosition.offsetX)}px;
        top: ${Math.min(_vm.currentPosition.offsetY, _vm.nextPosition.offsetY)}px;
        border: 2px solid ${this.color}`),attrs:{"data-op":`${_vm.nextPosition.offsetY}, ${_vm.currentPosition.offsetY}`}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMovingPos),expression:"isMovingPos"}],staticClass:"pointer-area"})],2)]),(_vm.disable)?_c('div',{staticClass:"transformmask"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }