<template>
  <div v-if="getPage?.url || getPage?.pic" id="pptContainer" class="ppt pptdisablearea">
  <!--
    <el-button v-if="!$route.path.includes('/s/')" icon="el-icon-edit pst_a" circle style="z-index:5;position: absolute;left:0.5rem;;top:0.5rem;" @click="setSyncUI({ drawUrl: Fn.hashToUrl(getPage.pic || getPage.url), drawShow: true, drawText: false })"></el-button>
    -->
    <el-tooltip v-if="!$route.path.includes('/s/')" class="item" effect="dark" content="Draw it" placement="top-start">
      <i @click="setSyncUI({ drawUrl: Fn.hashToUrl(getPage.pic || getPage.url), drawShow: true, drawText: false })" class="draw-button"></i>
    </el-tooltip>

    <div class="teacherppt" :style="`background-image:url(${Fn.hashToUrl(getPage.pic || getPage.url)})`">
      <StudentQuestions v-if="getQuestion?.type === 'comment' && $route.path.includes('/s/')" />
      <a v-if="getQuestion?.type === 'website'" :href="getQuestion.data" target="_blank" style="position: absolute; bottom: 1rem; right: 6rem; font-size: 2rem">
        <el-tooltip class="item" effect="dark" content="Embedded website link" placement="top">
          <el-button size="medium" circle><i class="el-icon-link f_1_5"></i></el-button>
        </el-tooltip>
      </a>
    </div>
    <div class="metarialcontent" v-if="(isMaterialShow || defaultShowMeterial) && getMaterial && (teacher || isStudentPaced)">
      <div class="medialist">
        <MetarialItem v-for="rect in getMaterial" :key="rect.id" :rect="rect" :teacher="teacher" />
      </div>
      <BigMetarialItem />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import StudentQuestions from './students/studentQuestions.vue'
import MetarialItem from './drag/metarialItem.vue'
import BigMetarialItem from './drag/bigMetarialItem.vue'
export default {
  props: {
    type: {type: String, default: ''},
    teacher: {
      type: Boolean,
      default: false,
    },
    defaultShowMeterial: {
      type: Boolean,
      default: false,
    },
    widthOffset: {
      type: Number,
      default: 40,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getPage', 'getQuestion', 'isMaterialShow', 'getMaterial', 'isStudentPaced']),
  },
  components: {
    StudentQuestions,
    MetarialItem,
    BigMetarialItem,
    // 'ppt-type': () => import('./common/pptType.vue')
  },
  data() {
    return {
      // width: 0,
      height: 0,
      parentHeight: 0,
      rectMediaList: [],
      hasData: false,
    }
  },
  created() {
    this.rectingDelay = null
    this.sizeDelay = null
  },
  methods: {
    ...mapActions(['setSyncUI']),
  },
  mounted() {
    this.height = document.documentElement.clientHeight - 110
    this.parentHeight = this.height - 76
  },
}
</script>

<style scoped>
.teacherppt {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  margin: 0 auto;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  height: 100%;
}
.ppt {
  width: 100%;
  height: 100%;
  display: flow-root;
  position: relative;
}
.pptdisablearea {
  background-color: #959595;
}
.metarialcontent {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1001;
}
.medialist {
  display: flex;
  width: 220px;
  height: 100%;
  /*overflow: scroll;*/
  overflow: auto;
  flex-direction: column;
  background-color: rgba(226, 235, 243, 1);
  align-items: center;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.full {
  width: 100%;
  height: 100%;
  position: relative;
}
.cursor {
  cursor: pointer;
}
.leftView {
  width: 150px;
  position: absolute;
  left: 0;
  overflow-y: scroll;
}
.draw-button {
  z-index:5;
  position: absolute;
  left:0.5rem;
  top:0.5rem;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-position: 10px 10px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  display: block;
  background-image: url(../assets/picture/draw.png);
}
.draw-button:hover {
  opacity: 0.8;
}
</style>
